import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';

// core components
import Group from './components/Group';

// redux
import { connect } from 'react-redux';
import AppInput from 'components/new_components/AppInput';

//translations
import { useTranslation } from 'react-i18next';

const IntegrationGroups = (props) => {
    const theme = useTheme();
    const { integrations_groups, setSelected } = props;

    // state
    const [keyword, setKeyword] = useState('');
    const [groups, setGroups] = useState([]);

    //translation
    const { t } = useTranslation('setups');

    // functions
    const handleKeywordChange = (e) => {
        setKeyword(e.target.value);
    };
    const isSearchMatch = (string) => {
        return string.toLowerCase().includes(keyword.toLowerCase());
    };
    const handleSearch = () => {
        const filteredGroups = [];
        integrations_groups.forEach((item) => {
            const group = JSON.parse(JSON.stringify(item));
            if (isSearchMatch(group?.name)) {
                filteredGroups.push(group);
            } else {
                const integrations = group?.integrations?.filter((integration) => {
                    return isSearchMatch(integration?.name);
                });
                if (integrations?.length) {
                    group.integrations = integrations;
                    filteredGroups.push(group);
                }
            }
        });
        setGroups(filteredGroups);
    };

    // useEffect
    useEffect(() => {
        if (keyword) {
            handleSearch();
        } else {
            setGroups(integrations_groups);
        }
    }, [keyword, integrations_groups]);
    return (
        <Box sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 3, px: 2.5, pb: 3 }}>
                <AppInput
                    placeholder={t('integrationsView.searchForApp')}
                    controlStyle={{ mt: 0, width: 'fit-content' }}
                    sx={{ maxWidth: 320, borderColor: theme.palette.neutral[60] }}
                    onChange={handleKeywordChange}
                />
            </Box>
            <Box sx={{ height: 'calc(100% - 0px)', overflow: 'auto' }}>
                {groups?.map((group) => {
                    return group.integrations.length ? (
                        <Group group={group} key={group.id} setSelected={setSelected} />
                    ) : null;
                })}
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        integrations_groups: state?.integrationsReducers?.integrations_groups,
    };
};
export default connect(mapStateToProps, {})(IntegrationGroups);
