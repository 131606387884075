import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import Light from 'assets/img/Light.svg';
import FolderIcon from 'assets/img/folder.svg';
import XslIcon from 'assets/img/xsl.svg';
import AppDrawer from 'components/new_components/AppDrawer';
import { AppForm, AppFormInput, AppFormSelect, AppSubmitButton } from 'components/new_components/forms';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { UploadResponse } from 'store/actions/vendorActions';
import { accept } from 'validate';
import { amlOptions, riskLevelOptions, vendorResponseInitialValues } from '../../utils/constants';
import { uploadExistingVendorResponseValidation, uploadVendorResponseValidation } from '../../utils/validation';
import { useTheme } from '@mui/styles';
import { AppFormContext } from 'components/new_components/forms';

// import AppSelectInput from 'components/new_components/AppSelectInput';

const ResponseDrawer = (props) => {
    const { open, handleClose, payload, vendors, merchantInfo } = props;
    const dispatch = useDispatch();
    const theme = useTheme();

    const [type, setType] = useState('new');
    const [loading, setLoading] = useState(false);
    const [responseDocument, setResponseDocument] = useState(null);
    const [values, setValues] = useState(payload);
    const [error, setError] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);

    const { excel } = accept;

    const handleVendorChange = (e) => {
        const selectedVendorId = e.target.value; // Get the selected vendor ID

        console.log(selectedVendorId);
        const vendor = vendors.find((v) => v.id == selectedVendorId); // Find the vendor object

        console.log(vendor, 'selected');
        setSelectedVendor(vendor); // Update the state with the selected vendor object
    };

    const dragEnter = () => {
        const target = document.getElementById('response-drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('response-drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                setResponseDocument(reader.result);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
        dragLeave();
    };

    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const fileSize = file.size / 1000 / 1000;
        if (fileSize > 2) {
            toast.info('File too large, minimum of 5MB');
            setResponseDocument('');
        } else {
            setResponseDocument(file);
            event.target.value = null;
        }
    };

    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    /*  const handleSubmit = async (values) => {
        setLoading(true);
        if (responseDocument === null) {
            setError(true);
            setLoading(false);
        } else {
            const formData = new FormData();
            formData.append('full_name', values.full_name);
            formData.append('service_description', values.service_description);
            formData.append('data_shared', values.data_shared);
            formData.append('products', values.products);
            formData.append('email', values.email);
            formData.append('is_aml_vendor', JSON.parse(values.is_aml_vendor));
            formData.append('file_upload', responseDocument);
            formData.append('risk_level', values?.risk_level);
            formData.append('min_risk_level', values?.min_risk_level);
            const res = await dispatch(UploadResponse(formData));
            setLoading(false);
            if (res?.success) {
                toast.success('Vendor responses Successfully uploaded');
                setResponseDocument(null);
                setValues(vendorResponseInitialValues);
                handleClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        }
    }; */

    // Updated handleSubmit function
    const handleSubmit = async (values) => {
        setLoading(true);
        setError(false);

        try {
            if (responseDocument === null) {
                setError(true);
                throw new Error('Please upload a file');
            }

            const formData = new FormData();

            if (type === 'existing' && selectedVendor) {
                formData.append('email', selectedVendor.email);
                formData.append('vendor_exists', true);
                formData.append('merchant_id', merchantInfo?.id);
                formData.append('vendor_id', selectedVendor.id);
            } else {
                formData.append('full_name', values.full_name);
                formData.append('service_description', values.service_description);
                formData.append('data_shared', values.data_shared);
                formData.append('products', values.products);
                formData.append('email', values.email);
                formData.append('is_aml_vendor', JSON.parse(values.is_aml_vendor));
                formData.append('risk_level', values.risk_level);
                formData.append('min_risk_level', values.min_risk_level);
            }

            formData.append('file_upload', responseDocument);

            const res = await dispatch(UploadResponse(formData));

            if (res?.success) {
                toast.success('Vendor responses successfully uploaded');
                setResponseDocument(null);
                setValues(vendorResponseInitialValues);
                handleClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    throw new Error(res?.message || 'Failed to upload vendor responses');
                }
            }
        } catch (error) {
            console.error('Error uploading vendor responses:', error);
            toast.error(error.message || 'An error occurred while uploading vendor responses');
        } finally {
            setLoading(false);
        }
    };

    const onClose = () => {
        setResponseDocument(null);
        setValues(vendorResponseInitialValues);
        handleClose();
    };

    const getCurrentValues = (values) => setValues(values);

    useEffect(() => {
        console.table(vendors, 'vendors');
    }, [vendors]);

    const handleTypeChange = (val) => {
        setType(val);
        setResponseDocument(null);
    };
    return (
        <AppForm
            initialValues={values}
            onSubmit={handleSubmit}
            validate={type === 'new' ? uploadVendorResponseValidation : uploadExistingVendorResponseValidation}
        >
            <AppFormContext getValues={getCurrentValues}>
                <AppDrawer
                    open={open}
                    handleClose={onClose}
                    title={'Upload Response'}
                    icon={<ArrowBackIos sx={{ width: 20, height: 20, color: '#000000' }} />}
                    noShadow
                    noClose
                    sx={{
                        overflow: 'hidden',
                    }}
                >
                    <Box sx={{ mx: 2 }}>
                        <Box
                            sx={{
                                border: '2px solid #395BA9',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    backgroundColor: type === 'new' ? '#395BA9' : 'transparent',
                                    width: '50%',
                                    color: type === 'new' ? '#FFFFFF !important' : '#395BA9 !important',
                                    textAlign: 'center',
                                    py: 1.2,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleTypeChange('new')}
                            >
                                New vendor
                            </Typography>
                            <Typography
                                sx={{
                                    backgroundColor: type === 'existing' ? '#395BA9' : 'transparent',
                                    width: '50%',
                                    color: type === 'existing' ? '#FFFFFF !important' : '#395BA9 !important',
                                    textAlign: 'center',
                                    py: 1.2,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleTypeChange('existing')}
                            >
                                Existing vendor
                            </Typography>
                        </Box>
                    </Box>

                    {type === 'new' ? (
                        <Stack
                            sx={{
                                p: 2,
                                maxWidth: '480px',
                                height: '1100px',
                                mt: '1rem',
                                pr: 1,
                                maxHeight: 'calc(100dvh - 13rem)',
                                [theme.breakpoints.down('lg')]: {
                                    height: '1100px',
                                },
                                overflowY: 'auto',

                                msOverflowStyle: 'none',
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: theme.palette.primary[900] + '30',
                                    borderRadius: '10px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: theme.palette.primary[900] + '50',
                                },
                                '&::-webkit-scrollbar-track-piece': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <Stack
                                flexDirection="row"
                                sx={{
                                    border: `1px solid #F0F0FA`,
                                    backgroundColor: '#F8FAFC',
                                    borderRadius: '4px',
                                    p: '12px',
                                    pt: '8px',
                                }}
                            >
                                <img src={Light} alt="file question" className="h-fit w-fit" />
                                <Typography
                                    sx={{
                                        color: '#64748B',
                                        fontWeight: 400,
                                        fontSize: '13px',
                                        ml: 1,
                                    }}
                                >
                                    Use this option only when the vendor answered the <br /> vendor questionnaire in a
                                    document sent to them by you.
                                </Typography>
                            </Stack>
                            <Box sx={{ mt: 2 }}>
                                <div>
                                    <p className="text-[13px] text-gray-500 font-medium">Vendor Name</p>
                                    <AppFormInput
                                        type="text"
                                        name="full_name"
                                        placeholder="Vendor Name"
                                        sx={{ mt: -2 }}
                                    />
                                </div>
                                <div className="mt-3">
                                    <p className="text-[13px] text-gray-500 font-medium">
                                        Questionnaire responder email
                                    </p>
                                    <AppFormInput type="text" name="email" placeholder="Vendor Email" sx={{ mt: -2 }} />
                                </div>
                                <div className="mt-3">
                                    <p className="text-[13px] text-gray-500 font-medium">Data Shared</p>
                                    <AppFormInput
                                        type="text"
                                        name="data_shared"
                                        placeholder="Data Shared"
                                        sx={{ mt: -2 }}
                                    />
                                </div>
                                <div className="mt-3">
                                    <p className="text-[13px] text-gray-500 font-medium">Products</p>
                                    <AppFormInput type="text" name="products" placeholder="Products" sx={{ mt: -2 }} />
                                </div>
                                <div className="mt-3">
                                    <p className="text-[13px] text-gray-500 font-medium">Questionnaire Type</p>
                                    <AppFormSelect
                                        name="is_aml_vendor"
                                        options={amlOptions}
                                        defaultValue="Vendor Questionnaire Type"
                                        sx={{
                                            height: '40px',

                                            '& .MuiSelect-select': {
                                                color: theme.palette.grey[900],
                                                fontSize: '14px',

                                                '& .MuiSelect-select': {
                                                    display: 'flex',
                                                },
                                            },
                                        }}
                                    />
                                </div>
                                <div className="mt-3">
                                    <p className="text-[13px] text-gray-500 font-medium">Risk Level</p>
                                    <AppFormSelect
                                        name="risk_level"
                                        options={riskLevelOptions}
                                        defaultValue="Risk level"
                                        sx={{
                                            height: '40px',

                                            '& .MuiSelect-select': {
                                                fontSize: '14px',

                                                color: theme.palette.grey[900],

                                                '& .MuiSelect-select': {
                                                    display: 'flex',
                                                },
                                            },
                                        }}
                                    />
                                </div>
                                <div className="mt-3">
                                    <p className="text-[13px] text-gray-500 font-medium">Vendor Minimum Risk Level</p>
                                    <AppFormInput
                                        type="number"
                                        name="min_risk_level"
                                        placeholder="Vendor minimum risk alert level"
                                        sx={{ mt: -2 }}
                                    />
                                </div>
                                <AppFormInput
                                    type="text"
                                    name="service_description"
                                    placeholder={'Description of service'}
                                    multiline={true}
                                    rows={2}
                                />
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        border: '1px dashed #CBD5E1',
                                        borderRadius: 2,
                                        padding: 1,
                                        minHeight: 150,
                                        position: 'relative',
                                        mt: 2,
                                    }}
                                >
                                    <label htmlFor="response-input" style={{ display: 'block', height: '100%' }}>
                                        <Box
                                            id="response-drop-zone"
                                            onDragEnter={dragEnter}
                                            onDragLeave={dragLeave}
                                            onDrop={handleDrop}
                                            onDragOver={dragOver}
                                            sx={{ height: '100%', p: 2 }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <img src={FolderIcon} alt={FolderIcon} />
                                                <Typography
                                                    sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500 }}
                                                >
                                                    Drop your files or click to upload
                                                </Typography>
                                                <Typography
                                                    sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}
                                                >
                                                    Supported file types: EXCEL:
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    mt: 1,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        border: '1px solid #E2E8F0',
                                                        cursor: 'pointer',
                                                        padding: 0.5,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}
                                                    >
                                                        Browse
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </label>
                                    <input
                                        type="file"
                                        accept={`${excel}`}
                                        onChange={(e) => uploadImage(e)}
                                        hidden
                                        id="response-input"
                                    />
                                </Box>
                                {error && !responseDocument && (
                                    <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#D91B1B', mt: 0.5 }}>
                                        Please upload file
                                    </Typography>
                                )}
                                {responseDocument && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mt: 1,
                                        }}
                                    >
                                        <img src={XslIcon} alt="icon" />
                                        <Box sx={{ ml: 1 }}>
                                            <Typography
                                                sx={{
                                                    color: '#475569',
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                {responseDocument.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    ) : (
                        <Stack
                            sx={{
                                p: 2,
                                maxWidth: '480px',
                            }}
                        >
                            <Stack
                                flexDirection="row"
                                sx={{
                                    border: `1px solid #F0F0FA`,
                                    backgroundColor: '#F8FAFC',
                                    borderRadius: '4px',
                                    p: '12px',
                                    pt: '8px',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <img src={Light} alt="file question" className="h-fit w-fit" />
                                <Typography
                                    sx={{
                                        color: '#64748B',
                                        fontWeight: 400,
                                        fontSize: '13px',
                                        ml: 1,
                                    }}
                                >
                                    Use this option only if the vendor already exist but they answered the vendor
                                    questionnaire in a document sent to them by you.
                                </Typography>
                            </Stack>
                            <Box sx={{ mt: 2 }}>
                                <div>
                                    <p className="text-[13px] text-gray-500 font-medium">Vendor name</p>
                                    <AppFormSelect
                                        name="vendor_id"
                                        options={vendors.map((vendor) => ({
                                            name: vendor.name,
                                            label: vendor.name,
                                            value: vendor.id,
                                        }))}
                                        onChange={handleVendorChange}
                                        defaultValue=" Vendor"
                                        sx={{ height: '40px', mt: -2 }}
                                    />
                                </div>
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        border: '1px dashed #CBD5E1',
                                        borderRadius: 2,
                                        padding: 1,
                                        minHeight: 150,
                                        position: 'relative',
                                        mt: 2,
                                    }}
                                >
                                    <label htmlFor="response-input" style={{ display: 'block', height: '100%' }}>
                                        <Box
                                            id="response-drop-zone"
                                            onDragEnter={dragEnter}
                                            onDragLeave={dragLeave}
                                            onDrop={handleDrop}
                                            onDragOver={dragOver}
                                            sx={{ height: '100%', p: 2 }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <img src={FolderIcon} alt={FolderIcon} />
                                                <Typography
                                                    sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500 }}
                                                >
                                                    Drop your files or click to upload
                                                </Typography>
                                                <Typography
                                                    sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}
                                                >
                                                    Supported file types: EXCEL:
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    mt: 1,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        border: '1px solid #E2E8F0',
                                                        cursor: 'pointer',
                                                        padding: 0.5,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}
                                                    >
                                                        Browse
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </label>
                                    <input
                                        type="file"
                                        accept={`${excel}`}
                                        onChange={(e) => uploadImage(e)}
                                        hidden
                                        id="response-input"
                                    />
                                </Box>
                                {error && !responseDocument && (
                                    <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#D91B1B', mt: 0.5 }}>
                                        Please upload file
                                    </Typography>
                                )}
                                {responseDocument && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mt: 1,
                                        }}
                                    >
                                        <img src={XslIcon} alt="icon" />
                                        <Box sx={{ ml: 1 }}>
                                            <Typography
                                                sx={{
                                                    color: '#475569',
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                {responseDocument.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    )}

                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            paddingLeft: '19rem',
                            backgroundColor: '#F8FAFC',
                            width: '100% !important',
                            py: 2.5,
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <AppSubmitButton
                            text="Upload"
                            variant="contained"
                            color="primary"
                            loading={loading}
                            loadingPosition="center"
                            sx={{ borderRadius: 1 }}
                        />
                    </Box>
                </AppDrawer>
            </AppFormContext>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        merchantInfo: state?.merchantReducers?.merchant_info,
    };
};
export default connect(mapStateToProps, null)(ResponseDrawer);
