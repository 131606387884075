import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

//redux
import { connect } from 'react-redux';
import { AddVendor, EditVendor } from 'store/actions/vendorActions';

//translations
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppDrawer from 'components/new_components/AppDrawer';

import VendorDetails from './VendorDetails';
import VendorScoreCard from './VendorScoreCard';
import VendorQuestionnaireHistory from './VendorQuestionnaireHistory';

import { GetQuestionnaireHistory } from 'store/actions/vendorActions';
import VendorSubTab from './components/VendorSubTab';

const VendorDetailsModal = (props) => {
    const { open, handleClose, details } = props;
    const [currentTab, setCurrentTab] = useState(0);

    //translation
    const { t } = useTranslation('vendorManagement');
    const onClose = () => {
        setCurrentTab(0);
        handleClose();
    };

    const tabs = [
        {
            index: 0,
            label: t('vendorDetailsModal.tab1'),
        },
        {
            index: 1,
            label: t('vendorDetailsModal.tab2'),
        },
        {
            index: 2,
            label: t('vendorDetailsModal.tab3'),
        },
    ];
    return (
        <AppDrawer
            open={open}
            handleClose={onClose}
            title={
                <Box>
                    <Typography sx={{ color: '#202D66', fontSize: '16px', fontWeight: 600 }}>
                        {t('vendorDetailsModal.title')}
                    </Typography>
                    <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 400 }}>
                        {details?.name}
                    </Typography>
                </Box>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            width="fit-content"
            height="100dvh"
            sx={{
                overflow: 'auto',
            }}
        >
            <Box sx={{ mx: '0', px: '1.5rem', my: 0, overflow: 'hidden', mt: '-1rem' }}>
                <>
                    <VendorSubTab
                        tabs={tabs}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        sx={{ mx: 'auto', overflow: 'hidden', fontFamily: 'poppins' }}
                    />

                    {currentTab === 0 ? (
                        <VendorDetails details={details} />
                    ) : currentTab === 1 ? (
                        <VendorQuestionnaireHistory details={details} />
                    ) : (
                        <VendorScoreCard details={details} />
                    )}
                </>
            </Box>
        </AppDrawer>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { AddVendor, EditVendor, GetQuestionnaireHistory })(VendorDetailsModal);
