import { useTheme } from '@mui/styles';
//components
import { Close } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import AppInput from './AppInput';
import FormTooltip from './FormTooltip';
const AppTagInputField = ({
    name,
    label,
    tags,
    setTags,
    placeholder,
    tagInputValue,
    setTagInputValue,
    isKeyReleased,
    setIsKeyReleased,
    toolTip,
    tips,
    disabled,
}) => {
    const theme = useTheme();
    //AppTagInput controls
    const onChange = (e) => {
        const { value } = e.target;
        setTagInputValue(value);
    };

    const onKeyDown = (e) => {
        const { key } = e;
        console.log(e, '...');
        const trimmedInput = tagInputValue?.trim();
        console.log();

        if (key === ',' && trimmedInput?.length && !tags?.includes(trimmedInput)) {
            e.preventDefault();
            setTags((prevState) => [...prevState, trimmedInput]);
            setTagInputValue('');
        }

        if (key === 'Backspace' && !tagInputValue?.length && tags?.length && isKeyReleased) {
            const tagsCopy = [...tags];
            const poppedTag = tagsCopy?.pop();
            e.preventDefault();
            setTags(tagsCopy);
            setTagInputValue(poppedTag);
        }
        setIsKeyReleased(false);
    };
    const onKeyUp = () => {
        setIsKeyReleased(true);
    };
    const deleteTag = (index) => {
        setTags((prevState) => prevState.filter((tag, i) => i !== index));
    };
    return (
        <>
            <label
                className={`text-[13px] mt-3 mb-[-0.5rem] font-medium  ${
                    disabled ? 'text-[#DCDEE5]' : 'text-[#64748b]'
                }`}
            >
                <>
                    {label}
                    {toolTip && <FormTooltip placement="top" text={tips} />}
                </>
            </label>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                    overflow: 'auto',
                    width: '100%',
                    maxWidth: '100%',
                    border: '0.3px solid #e2e8f0',
                    borderRadius: '4px',
                }}
            >
                {tags?.map((tag, idx) => (
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        key={idx}
                        sx={{
                            whiteSpace: 'nowrap',
                            fontSize: 12,
                            border: `1px solid ${disabled ? '#DCDEE5' : theme.palette.primary.main}`,
                            mx: 0.5,
                            my: 0,
                            px: 0.5,
                            py: 0.3,
                            color: theme.palette.primary.main,
                            borderRadius: '16px',
                        }}
                    >
                        <Button
                            className="ml-1"
                            disabled={disabled}
                            onClick={() => deleteTag(idx)}
                            sx={{ minWidth: 'fit-content', py: 0, textTransform: 'capitalize', fontSize: 12 }}
                        >
                            {tag}
                            <Close sx={{ fontSize: 13, ml: 1 }} />
                        </Button>
                    </Stack>
                ))}
                <AppInput
                    name={name}
                    value={tagInputValue}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    placeholder={placeholder}
                    sx={{
                        width: '100%',
                        minWidth: '50%',
                        border: 'none',
                        mt: 0,
                    }}
                    controlStyle={{ mt: 0 }}
                    disabled={disabled}
                />
            </Stack>
        </>
    );
};

export default AppTagInputField;
