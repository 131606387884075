import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import CircularLoader from 'components/Loaders/CircularLoader';
import VendorSectionLayout from './VendorSectionLayout';
import Questionnaire from './Questionnaire';
// import { AppForm } from 'components/new_components/forms';

// redux
import { connect } from 'react-redux';
import {
    GetQuestionnaireForms,
    GetVendorResponses,
    CreateVendorResponse,
    EditVendorResponse,
    UpdateVendorDetails,
    UploadFile,
} from 'store/actions/vendorActions';

// validation
// import { validation } from 'validate';
import { Box } from '@mui/material';

//translation
// import { Box } from '@mui/material';
// import { Language } from '@mui/icons-material';
// import LanguageMenu from 'components/new_components/merchant-layout/header/menus/LanguageMenu';
import { useLocation } from 'react-router-dom';
import { VendorAttestation } from './VendorAttestation';
import { UpdateQuestionnaireHistory } from 'store/actions/vendorActions';

const QuestionnaireFormsView = (props) => {
    // props
    const {
        vendor,
        readonly,
        merchant,
        page,
        setPage,
        GetQuestionnaireForms,
        GetVendorResponses,
        CreateVendorResponse,
        EditVendorResponse,
        // UpdateVendorDetails,
        UpdateQuestionnaireHistory,
        questionnaire_forms,
        vendor_responses,
        UploadFile,
        one_vendor,
        questionnaireValue,
    } = props;
    let location = useLocation();

    // state
    const [loading, setLoading] = useState({
        content: false,
        form: false,
    });
    const [uploading, setUploading] = useState({});
    const [sortedQuestionnaireForms, setSortedQuestionnaireForms] = useState([]);
    const [currentQuestionnaireSection, setCurrentQuestionnaireSection] = useState(null);
    const [currentResponse, setCurrentResponse] = useState(null);
    const [files, setFiles] = useState({});
    const [responses, setResponses] = useState({
        answer: {},
    });
    const [openAttestationModal, setAttestationModal] = useState(false);

    // const [anchorEl, setAnchorEl] = useState(null);
    const is_aml = location?.pathname?.split('/')?.find((item) => item === 'is_aml=True');
    //translation
    const language = localStorage.getItem('i18nextLng');

    // const open = Boolean(anchorEl);
    // const openLanguageMenu = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const closeLanguageMenu = () => {
    //     setAnchorEl(null);
    // };

    // functions
    // async functions
    const getQuestionnaire = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetQuestionnaireForms();
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getVendorResponse = async (ven, params) => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetVendorResponses(ven, params);
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const handleSubmit = async () => {
        // // creating a new instance of the values
        // const answer = { ...JSON.parse(JSON.stringify(values)), ...files };
        // // turning arrays to comma separated values
        // Object.keys(answer).forEach((key) => {
        //     const value = answer[key];
        //     answer[key] = value && value.constructor === Array ? value.join(',') : value;
        // });
        // // create payload
        // const payload = { answer, vendor };
        const isLastPage = page >= sortedQuestionnaireForms.length;

        if (currentResponse) {
            await handleEditAnswers();
        } else {
            await handleSubmitAnswers();
        }

        if (isLastPage) {
            setAttestationModal(true);
        }
    };

    const handleSubmitAnswers = async () => {
        const isLastPage = page >= sortedQuestionnaireForms.length;
        const payload = {
            form_id: currentQuestionnaireSection?.id,
            merchant: merchant,
            answer: responses.answer,
            vendor,
            vendor_questionnaire: questionnaireValue,
        };

        if (Object?.keys(responses?.answer || {})?.length !== currentQuestionnaireSection?.form_fields?.length) {
            toast.info('Please answer all questions');
        } else {
            setLoading((loading) => ({ ...loading, form: true }));
            const res = await CreateVendorResponse(payload);
            setLoading((loading) => ({ ...loading, form: false }));
            if (res?.success) {
                if (!isLastPage) {
                    goToNextSection();
                }
            } else {
                toast.error(res?.message);
            }
        }
    };
    const handleEditAnswers = async () => {
        const isLastPage = page >= sortedQuestionnaireForms.length;
        const payload = {
            form_id: currentQuestionnaireSection?.id,
            merchant: merchant,
            answer: responses.answer,
            vendor,
            vendor_questionnaire: questionnaireValue,
        };
        if (Object?.keys(responses?.answer || {})?.length !== currentQuestionnaireSection?.form_fields?.length) {
            toast.info('Please answer all questions');
        } else {
            setLoading((loading) => ({ ...loading, form: true }));
            const res = await EditVendorResponse(payload, currentResponse?.id);
            setLoading((loading) => ({ ...loading, form: false }));
            if (res?.success) {
                if (!isLastPage) {
                    goToNextSection();
                }
            } else {
                toast.error(res?.message);
            }
        }
    };
    /*    
    const sendResponded = async (signature, position = '', recipient_name) => {
        const payload = {
            signature,
            position,
            recipient_name,
            date_signed: new Date().toISOString(),
            has_responded: true,
        };

        setLoading((loading) => ({ ...loading, form: true }));
        const res = await UpdateQuestionnaireHistory(questionnaireValue, payload);
        setLoading((loading) => ({ ...loading, form: false }));
        if (res?.success) {
            return toast.success('Your response has been sent to the merchant.');
        } else {
            toast.error(res?.message);
        }
    }; */
    const sendResponded = async (signature, position = '', recipient_name = '') => {
        try {
            setLoading((prev) => ({ ...prev, form: true }));
            const formData = new FormData();
            formData.append('has_responded', true);
            formData.append('signature', signature);
            formData.append('date_signed', new Date().toISOString());
            formData.append('recipient_name', recipient_name);
            if (position) {
                formData.append('position', position);
            }
            const res = await UpdateQuestionnaireHistory(vendor, questionnaireValue, formData);
            if (res?.success) {
                return toast.success('Your response has been sent to the merchant.');
            }
        } catch (error) {
            toast.error(error?.message);
        } finally {
            setLoading((prev) => ({ ...prev, form: false }));
        }
    };

    const handleFileUpload = async (file, name) => {
        const formData = new FormData();
        formData.append('file_upload', file);
        setUploading((uploading) => ({ ...uploading, [name]: true }));
        const res = await UploadFile(formData);
        setUploading((uploading) => ({ ...uploading, [name]: false }));

        if (res?.success) {
            setFiles((current) => ({ ...current, [name]: res?.file_url }));
            if (Array.isArray(responses?.answer?.[name])) {
                setResponses({
                    answer: {
                        ...responses?.answer,
                        [name]: responses?.answer?.[name]?.filter(Boolean)?.concat([res?.file_url]),
                    },
                });
            }
            toast.success('File upload successfully!');
        } else {
            toast.error(res?.message);
        }
    };

    // navigation functions
    const goToPage = (page) => setPage(page);
    const goToNextSection = () => setPage((page) => page + 1);
    const goToPrevSection = () => setPage((page) => page - 1);

    // const getInitialValues = (questionnaire, response) => {
    //     const initialValues = {};
    //     questionnaire?.form_fields?.forEach((field) => {
    //         const fieldValue = response?.answer?.[field?.id];
    //         const arrayFields = ['checkboxes'];
    //         initialValues[field?.id] = (arrayFields.includes(field?.type) ? fieldValue?.split(',') : fieldValue) || '';
    //     });
    //     return initialValues;
    // };
    // const validate = (values, questionnaire) => {
    //     const errors = {};
    //     questionnaire?.form_fields.forEach((field) => {
    //         if (field.type === 'file') return;
    //         const fieldValidate = validation(values[field?.id], 'Field', field?.required);
    //         if (!fieldValidate.isValid) {
    //             errors[field?.id] = fieldValidate.errorMessage;
    //         }
    //     });
    //     return errors;
    // };

    // useEffect
    useEffect(() => {
        if (vendor && questionnaireValue) {
            getQuestionnaire();

            getVendorResponse(vendor, {
                vendor_questionnaire: questionnaireValue,
            });
        }
    }, [vendor, merchant, questionnaireValue, readonly]);
    useEffect(() => {
        if (questionnaire_forms) {
            const sortedQuestionnaire = questionnaire_forms?.sort((a, b) => a.id - b.id);
            const modified_question = sortedQuestionnaire?.filter(
                (question) => question?.title !== 'Anti-Money Laudering'
            );
            setSortedQuestionnaireForms(is_aml ? sortedQuestionnaire : modified_question);
        }
    }, [questionnaire_forms]);
    useEffect(() => {
        // find the section and response corresponding to that particular page
        const currentQuestionnaireSection = sortedQuestionnaireForms[page - 1];
        const currentResponse = vendor_responses?.find(
            (response) => response?.form_id === currentQuestionnaireSection?.id
        );

        setCurrentQuestionnaireSection(currentQuestionnaireSection);
        setCurrentResponse(currentResponse);
        setResponses({
            answer: currentResponse?.answer,
        });
    }, [page, sortedQuestionnaireForms]);
    useEffect(() => {
        if (vendor_responses?.length?.length > 0) {
            setPage(vendor_responses?.length + 1);
        }
    }, [vendor_responses]);

    return (
        // <AppForm
        //     initialValues={getInitialValues(currentQuestionnaireSection, currentResponse)}
        //     onSubmit={handleSubmit}
        //     validate={(value) => validate(value, currentQuestionnaireSection)}
        // >
        <Box>
            {/* <Box sx={{ display: 'flex', justifyContent: 'right', mx: '12.5%', mt: '-40px' }}>
                <Box className="text-[14px] font-medium" onClick={openLanguageMenu}>
                    <Language fontSize="small" className="mr-2" />
                    {window.localStorage.i18nextLng?.includes('en')
                        ? 'English'
                        : window.localStorage.i18nextLng?.includes('fr')
                        ? 'French'
                        : window.localStorage.i18nextLng}
                </Box>
                <LanguageMenu
                    open={open}
                    closeLanguageMenu={closeLanguageMenu}
                    anchorEl={anchorEl}
                    language={
                        window.localStorage.i18nextLng?.includes('en')
                            ? 'English'
                            : window.localStorage.i18nextLng?.includes('fr')
                            ? 'French'
                            : window.localStorage.i18nextLng
                    }
                    menuListStyles={{
                        parent: {
                            right: '15%',
                        },
                    }}
                />
            </Box> */}
            <VendorSectionLayout
                questionnaireFormsCount={sortedQuestionnaireForms?.length}
                categories={sortedQuestionnaireForms?.map((question) =>
                    language?.includes('fr')
                        ? question?.title_fr
                        : language?.includes('en')
                        ? question?.title_en
                        : question?.title
                )}
                setPage={setPage}
                page={page}
                showFooter={true && !loading.content}
                readonly={readonly}
                onNextClick={goToNextSection}
                onPrevClick={goToPrevSection}
                onPageChange={goToPage}
                loading={loading}
                responseCount={vendor_responses?.length}
                handleSubmit={handleSubmit}
            >
                {!loading.content ? (
                    <Questionnaire
                        questionnaire={currentQuestionnaireSection}
                        vendorResponse={currentResponse}
                        readonly={readonly}
                        vendor={vendor}
                        merchant={merchant}
                        CreateVendorResponse={CreateVendorResponse}
                        EditVendorResponse={EditVendorResponse}
                        handleFileUpload={handleFileUpload}
                        uploading={uploading}
                        files={files}
                        responses={responses}
                        setResponses={setResponses}
                        setFiles={setFiles}
                    />
                ) : (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularLoader />
                    </div>
                )}
            </VendorSectionLayout>
            <VendorAttestation
                open={openAttestationModal}
                handleClose={() => setAttestationModal(false)}
                vendor={one_vendor}
                handleSubmit={(signature, position, name) => sendResponded(signature, position, name)}
                loading={loading.form == true}
            />
        </Box>
        // </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        questionnaire_forms: state?.vendorReducers?.questionnaire_forms,
        vendor_responses: state?.vendorReducers?.vendor_responses,
    };
};
export default connect(mapStateToProps, {
    GetQuestionnaireForms,
    GetVendorResponses,
    CreateVendorResponse,
    EditVendorResponse,
    UpdateVendorDetails,
    UploadFile,
    UpdateQuestionnaireHistory,
})(QuestionnaireFormsView);
