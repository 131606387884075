import React from 'react';
import { Typography } from '@mui/material';
import QuestionnaireIcon from '../../../../../../assets/img/icons/questionnaire.svg';
import VendorTooltip from './VendorTooltip';

const QuestionnaireCountTooltip = ({ row, theme }) => {
    // Calculate answered and unanswered counts
    const questionnaireHistory = row?.questionnaire_history || [];
    const answeredCount = questionnaireHistory.filter((q) => q.has_responded).length;
    const unansweredCount = questionnaireHistory.length - answeredCount;

    return (
        <VendorTooltip
            title={
                <div>
                    <div>Answered: {answeredCount}</div>
                    <div>Unanswered: {unansweredCount}</div>
                </div>
            }
            arrow
            placement="bottom"
        >
            <Typography
                sx={{
                    borderRight: '2px solid #e2e8f0',
                    px: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.gray[500],
                    fontSize: '14px',
                    fontWeight: 500,
                    py: '1rem',
                    height: '100%',
                    cursor: 'pointer', // Add cursor pointer to indicate it's hoverable
                }}
            >
                <img src={QuestionnaireIcon} className="w-[16px] h-[16px]" alt="Questionnaire Icon" />
                &nbsp;
                <span className="whitespace-nowrap pr-3">
                    {row?.questionnaire_history?.length > 1
                        ? row?.questionnaire_history?.length + ' questionnaires'
                        : (row?.questionnaire_history?.length || 0) + ' questionnaire'}
                </span>
            </Typography>
        </VendorTooltip>
    );
};

export default QuestionnaireCountTooltip;
