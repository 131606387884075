import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Pagination, PaginationItem } from '@mui/material';

// core component
import TableEndDivider from './TableEndDivider';
import { getNoOfPages } from './utils';

const AppTablePagination = (props) => {
    const { page, onPageChange, dataLength, noPerPage, count } = props;
    const theme = useTheme();

    const noOfPages = useMemo(() => getNoOfPages(dataLength, noPerPage), [noPerPage, dataLength]);
    return (
        <Stack sx={{ mt: 3, mb: 1.5 }} direction="row" alignItems="center" justifyContent="flex-end">
            <TableEndDivider count={count} isLast={noOfPages == page} />
            <Pagination
                count={noOfPages || 0}
                page={page}
                shape="rounded"
                color="primary"
                sx={{ p: 2 }}
                onChange={(e, page) => onPageChange(page)}
                renderItem={(item) => {
                    const isActive = item.page === page;
                    const isNav = item.type !== 'page';
                    return (
                        <PaginationItem
                            {...item}
                            sx={{
                                border: isActive || isNav ? 'none' : '1px solid',
                                height: '28px',
                                borderRadius: '8px',
                                borderColor: theme.palette.gray[100],
                            }}
                        />
                    );
                }}
            />
        </Stack>
    );
};

export default AppTablePagination;
