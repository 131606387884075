import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DeleteVendor } from 'store/actions/vendorActions';
import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';

const VendorDeleteModal = (props) => {
    const { open, handleClose, DeleteVendor, id } = props;

    const [deleting, setDeleting] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation('vendorManagement');

    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeleteVendor(id);
        setDeleting(false);

        if (res?.success) {
            toast.success('Successfully deleted a Vendor.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="vendor-delete-modal-title"
            aria-describedby="vendor-delete-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'white',
                    boxShadow: 24,
                    borderRadius: 2,
                    outline: 'none',
                }}
            >
                {/* Modal Content */}
                <Box
                    sx={{
                        p: 3,
                        textAlign: 'center',
                        background: '#fff',
                    }}
                >
                    <Typography
                        id="vendor-delete-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            fontWeight: 600,
                            fontSize: '18px',
                            color: theme.palette.gray[600],
                        }}
                    >
                        {t('vendorDeleteModal.title')}
                    </Typography>

                    <Typography
                        id="vendor-delete-modal-description"
                        sx={{
                            mt: 2,
                            color: theme.palette.gray[500],
                            fontSize: '14px',
                        }}
                    >
                        {t('vendorDeleteModal.subtitle')}
                    </Typography>
                </Box>

                {/* Footer with Buttons */}
                <Box
                    sx={{
                        bgcolor: '#F8FAFC',
                        py: 3,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        borderTop: '1px solid #E5E7EB',
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.gray[900],
                            background: '#fff',
                            border: '1px solid #E2E8F0',
                        }}
                    >
                        {t('vendorDeleteModal.cancel')}
                    </Button>

                    <LoadingButton
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        loading={deleting}
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#F43F5E',
                            '&:hover': {
                                backgroundColor: '#E11D48',
                            },
                        }}
                    >
                        {t('vendorDeleteModal.deleteVendor')}
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps, {
    DeleteVendor,
})(VendorDeleteModal);
