import React from 'react';
import { Container, Card, TableContainer, Table, Stack } from '@mui/material';

// core components
import TableTopbar from './TableTopbar';

import TableHeader from './TableHeader';
import AppTableBody from './AppTableBody';
import AppTablePagination from './AppTablePagination';

// import { useTheme } from '@mui/styles';

const VendorAppTable = (props) => {
    const {
        columns,
        data,
        title,
        actions,
        sorter,
        page,
        onPageChange,
        count = '',
        emptyStateText,
        noPerPage,
        dataLength,
        loading,
        sx = {},
        rowSpacing,
        onRowClick,
        border,
        clickableRow,
        search,
        onSearch,
        toggleTabs,
        tabs,
        tabState,
        showPagination = true,
        noBackground,
        rowBorder,
        ref1,
        ref2,
        implementer,
        tooltip,
        colored_cell_color,
    } = props;
    const spacingBorderStyles = {
        borderCollapse: 'separate',
    };
    const borderStyles = {
        border: '0.5px solid #77777A',
        borderRadius: 8,
    };
    // const theme = useTheme();
    return (
        <Container
            component={Card}
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: data?.length ? '100%' : '',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
                ...sx?.container,
            }}
        >
            <Stack sx={{ flex: 1 }}>
                <TableTopbar
                    title={title}
                    actions={actions}
                    search={search}
                    onSearch={onSearch}
                    rowSpacing={rowSpacing}
                    toggleTabs={toggleTabs}
                    tabs={tabs}
                    tabState={tabState}
                    ref1={ref1}
                    ref2={ref2}
                />

                <TableContainer
                    sx={{
                        flex: 1,
                        p: 0,
                        border: implementer ? '1px solid #F1F5F9' : '1px solid #E2E8F0',
                        borderRadius: implementer ? '5px' : 2,
                    }}
                    component={Stack}
                >
                    <Table
                        sx={{
                            ...(rowSpacing ? spacingBorderStyles : {}),
                            ...(border ? borderStyles : implementer ? '1px solid #F1F5F9 ' : {}),
                            borderRadius: implementer ? '5px' : 'inherit',
                            overflow: implementer ? 'hidden' : 'inherit',
                            color: '#64748B',
                            p: 0,
                        }}
                    >
                        <TableHeader columns={columns} implementer={implementer} noBackground={noBackground} />
                        <AppTableBody
                            columns={columns}
                            data={data}
                            sorter={sorter}
                            emptyStateText={emptyStateText}
                            loading={loading}
                            rowSpacing={rowSpacing}
                            clickableRow={clickableRow}
                            onRowClick={onRowClick}
                            rowBorder={rowBorder}
                            implementer={implementer}
                            colored_cell_color={colored_cell_color}
                            tooltip={tooltip}
                        />
                    </Table>
                </TableContainer>
            </Stack>
            {!!data?.length && showPagination && (
                <AppTablePagination
                    count={count}
                    page={page}
                    onPageChange={onPageChange}
                    noPerPage={noPerPage}
                    dataLength={dataLength}
                />
            )}
        </Container>
    );
};

export default VendorAppTable;
