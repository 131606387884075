import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function RiskMatrix({ matrix, risk_level }) {
    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.risk_management_settings);
    const overview = useSelector((state) => state?.riskAssessmentReducers?.overview);

    const risk5x5Matrix = {
        columnLabels: get_risk_settings?.[0]?.columns_5x5,
        rowLabels: get_risk_settings?.[0]?.rows_5x5,
        data: risk_level === 'inherent' ? overview?.risk_heat_map_5x5?.inherent : overview?.risk_heat_map_5x5?.residual,
    };
    const risk3x3Matrix = {
        columnLabels: get_risk_settings?.[0]?.columns_3x3,
        rowLabels: get_risk_settings?.[0]?.rows_3x3,
        data: risk_level === 'inherent' ? overview?.risk_heat_map_3x3?.inherent : overview?.risk_heat_map_3x3?.residual,
    };

    return (
        <div className="col-span-6 bg-white rounded-md border-[#f1f5f9] px-3 py-3">
            {matrix === '3x3' ? (
                <ThreeByThreeMatrix
                    risk3x3Matrix={risk3x3Matrix}
                    get_risk_settings={get_risk_settings?.[0]?.matrix_3x3}
                />
            ) : (
                <FiveByFiveMatrix
                    risk5x5Matrix={risk5x5Matrix}
                    get_risk_settings={get_risk_settings?.[0]?.matrix_5x5}
                />
            )}
        </div>
    );
}

function FiveByFiveMatrix({ risk5x5Matrix, get_risk_settings }) {
    const [localColumnLabels, setLocalColumnLabels] = useState({
        0: 'Insignificant',
        1: 'Minor',
        2: 'Significant',
        3: 'Major',
        4: 'Severe',
    });
    const [localRowLabels, setLocalRowLabels] = useState({
        0: 'Rare',
        1: 'Unlikely',
        2: 'Moderate',
        3: 'Likely',
        4: 'Almost Certain',
    });

    // const greenBoxes = [2, 6, 10];
    // const deepGreenBoxes = [0, 1, 5];
    // const yellowBoxes = [3, 4, 7, 8, 11, 12, 15, 16, 20];
    // const orangeBoxes = [9, 13, 17, 21];
    // const redBoxes = [14, 18, 22, 19, 23, 24];
    const getBoxColor = (color) => {
        return color === 'very_low'
            ? 'bg-[#006C46] text-[#ffffff]'
            : color === 'low'
            ? 'bg-[#55BE8B] text-[#ffffff]'
            : color === 'medium'
            ? 'bg-[#F6EB50] text-[#475569]'
            : color === 'high'
            ? 'bg-[#DE3730] text-[#ffffff]'
            : color === 'very_high'
            ? 'bg-[#93000A] text-[#ffffff]'
            : 'bg-[#E2E8F0] text-transparent';
    };

    const matrices = [
        [
            { index: 0, value: get_risk_settings?.[0] },
            { index: 1, value: get_risk_settings?.[1] },
            { index: 2, value: get_risk_settings?.[2] },
            { index: 3, value: get_risk_settings?.[3] },
            { index: 4, value: get_risk_settings?.[4] },
        ],
        [
            { index: 5, value: get_risk_settings?.[5] },
            { index: 6, value: get_risk_settings?.[6] },
            { index: 7, value: get_risk_settings?.[7] },
            { index: 8, value: get_risk_settings?.[8] },
            { index: 9, value: get_risk_settings?.[9] },
        ],
        [
            { index: 10, value: get_risk_settings?.[10] },
            { index: 11, value: get_risk_settings?.[11] },
            { index: 12, value: get_risk_settings?.[12] },
            { index: 13, value: get_risk_settings?.[13] },
            { index: 14, value: get_risk_settings?.[14] },
        ],
        [
            { index: 15, value: get_risk_settings?.[15] },
            { index: 16, value: get_risk_settings?.[16] },
            { index: 17, value: get_risk_settings?.[17] },
            { index: 18, value: get_risk_settings?.[18] },
            { index: 19, value: get_risk_settings?.[19] },
        ],
        [
            { index: 20, value: get_risk_settings?.[20] },
            { index: 21, value: get_risk_settings?.[21] },
            { index: 22, value: get_risk_settings?.[22] },
            { index: 23, value: get_risk_settings?.[23] },
            { index: 24, value: get_risk_settings?.[24] },
        ],
    ];

    useEffect(() => {
        risk5x5Matrix?.columnLabels && setLocalColumnLabels(risk5x5Matrix?.columnLabels);
        risk5x5Matrix?.rowLabels && setLocalRowLabels(risk5x5Matrix?.rowLabels);
    }, [risk5x5Matrix]);

    return (
        <div className="flex gap-6 relative px-1.5">
            <p className="text-[#475569] font-semibold text-sm -rotate-90 h-fit flex self-start w-0 mt-[10rem]">
                Likelihood
            </p>

            <div className="flex gap-1.5">
                <div className="flex flex-col-reverse gap-1.5 w-[110px]">
                    <div className="flex-1" />

                    {(Object?.values(localRowLabels) || [])?.map((label, index) => (
                        <div key={index} className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-10 focus-within:bg-black/10 transition-colors">
                                <p className="text-xs font-medium text-[#64748B] text-center break-all">{label}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col">
                    <div className="grid grid-cols-[repeat(5,minmax(0,100px))] gap-1.5 relative p-0">
                        {matrices?.map((el, key) => {
                            return (
                                <div className="flex flex-col-reverse gap-1.5" key={key}>
                                    <div className="flex-1 !rounded-sm">
                                        <div className="grid place-items-center h-10 focus-within:bg-black/10 transition-colors">
                                            <p className="text-xs font-medium text-[#64748B] text-center break-all">
                                                {localColumnLabels[key.toString()]}
                                            </p>
                                        </div>
                                    </div>

                                    {el?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`flex-1 !rounded-sm ${
                                                    risk5x5Matrix?.data?.[item?.index]?.[`${item?.index}`] > 0
                                                        ? `${getBoxColor(item?.value)}`
                                                        : 'bg-[#E2E8F0]'
                                                }`}
                                            >
                                                <p
                                                    className={`truncate capitalize font-medium text-[13px] h-[50px] flex place-items-center justify-center`}
                                                >
                                                    {risk5x5Matrix?.data?.[item?.index]?.[`${item?.index}`] > 0
                                                        ? risk5x5Matrix?.data?.[item?.index]?.[`${item?.index}`]
                                                        : ''}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>

                    <p className="text-[#475569] font-semibold text-sm h-fit flex self-center">Impact</p>
                </div>
            </div>
        </div>
    );
}

function ThreeByThreeMatrix({ risk3x3Matrix, get_risk_settings }) {
    const [localColumnLabels, setLocalColumnLabels] = useState({
        0: 'Insigificant',
        1: 'Minor',
        2: 'Insigificant',
    });
    const [localRowLabels, setLocalRowLabels] = useState({
        0: 'Rare',
        1: 'Unlikely',
        2: 'Moderately',
    });

    // const greenBoxes = [0, 1, 3];
    // const yellowBoxes = [2, 4, 6];
    // const redBoxes = [5, 8, 7];
    const getBoxColor = (color) => {
        return color === 'low'
            ? 'bg-[#55BE8B] text-[#ffffff]'
            : color === 'medium'
            ? 'bg-[#F6EB50] text-[#475569]'
            : color === 'high'
            ? 'bg-[#DE3730] text-[#ffffff]'
            : 'bg-[#E2E8F0] text-transparent';
    };

    const matrices = [
        [
            { index: 0, value: get_risk_settings?.[0] },
            { index: 1, value: get_risk_settings?.[1] },
            { index: 2, value: get_risk_settings?.[2] },
        ],
        [
            { index: 3, value: get_risk_settings?.[3] },
            { index: 4, value: get_risk_settings?.[4] },
            { index: 5, value: get_risk_settings?.[5] },
        ],
        [
            { index: 6, value: get_risk_settings?.[6] },
            { index: 7, value: get_risk_settings?.[7] },
            { index: 8, value: get_risk_settings?.[8] },
        ],
    ];

    useEffect(() => {
        risk3x3Matrix?.columnLabels && setLocalColumnLabels(risk3x3Matrix?.columnLabels);
        risk3x3Matrix?.rowLabels && setLocalRowLabels(risk3x3Matrix?.rowLabels);
    }, [risk3x3Matrix]);

    return (
        <div className="flex gap-6 relative pr-8 pl-1.5">
            <p className="text-[#475569] font-semibold text-sm -rotate-90 h-fit flex self-start w-0 mt-[7rem]">
                Likelihood
            </p>

            {localRowLabels && (
                <div className="flex gap-3">
                    <div className="flex flex-col-reverse gap-1.5 w-[110px]">
                        <div className="flex-1" />

                        {(Object?.values(localRowLabels) || [])?.map((label, index) => (
                            <div key={index} className="flex-1 !rounded-sm">
                                <div className="grid place-items-center h-[50px] focus-within:bg-black/10 transition-colors">
                                    <p className="text-xs font-medium text-[#64748B] text-center break-all">{label}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col">
                        <div className="grid grid-cols-[repeat(3,minmax(0,136px))] gap-1.5 relative p-0">
                            {matrices?.map((el, key) => {
                                return (
                                    <div className="flex flex-col-reverse gap-1.5" key={key}>
                                        <div className="flex-1 !rounded-sm">
                                            <div className="grid place-items-center h-[50px] focus-within:bg-black/10 transition-colors">
                                                <p className="text-xs font-medium text-[#64748B] text-center break-all">
                                                    {localColumnLabels[key.toString()]}
                                                </p>
                                            </div>
                                        </div>

                                        {el?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`flex-1 !rounded-sm ${
                                                    risk3x3Matrix?.data?.[item?.index]?.[`${item?.index}`] > 0
                                                        ? `${getBoxColor(item?.value)}`
                                                        : 'bg-[#E2E8F0]'
                                                }`}
                                            >
                                                <p
                                                    className={`truncate capitalize font-medium text-[13px] h-[50px] flex place-items-center justify-center`}
                                                >
                                                    {risk3x3Matrix?.data?.[item?.index]?.[item?.index]}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>

                        <p className="text-[#475569] font-semibold text-sm h-fit flex self-center">Impact</p>
                    </div>
                </div>
            )}
        </div>
    );
}
