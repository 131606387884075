import { ExpandMore } from '@mui/icons-material';
import { FormControl, FormHelperText, MenuItem, Radio, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import { formatSentence } from 'utils';

const VendorFormSelect = ({
    label,
    labelStyle,
    name,
    options,
    value,
    error,
    multiple,
    disabled,
    loading,
    type,
    defaultValue,
    sx = {},
    MenuProps = {},
    onClick,
    noDataLabel,
    custom,
    ...restProps
}) => {
    const theme = useTheme();

    // get the formik context values
    const { setFieldTouched, handleChange, touched, values, errors } = useFormikContext();
    const initialValue = multiple ? [] : '';

    const handleSelectChange = (e) => {
        handleChange(name)(e);
        setTimeout(() => setFieldTouched(name), 400);
    };
    const handleBlur = () => {
        if (restProps.options?.includes('Yes')) {
            return null;
        } else {
            setFieldTouched(name);
        }
    };

    const newValue =
        typeof value === 'string' || typeof value === 'number'
            ? multiple
                ? value?.split(',')
                : value?.toString()?.trim()
            : value?.map((item) => item.toString().trim());

    return (
        <>
            <FormControl
                name={name}
                label={<>{label}</>}
                error={error}
                disabled={disabled}
                labelStyle={labelStyle ? labelStyle : { color: '#64748B', fontWeight: 500, fontSize: '13px' }}
                fullWidth
                sx={{ mt: 2, width: name === 'type' ? 'unset' : '100%', ...sx }}
            >
                <Select
                    displayEmpty
                    IconComponent={ExpandMore}
                    disabled={disabled}
                    value={(values && values[name]) || initialValue}
                    onChange={handleSelectChange}
                    onBlur={handleBlur}
                    error={touched[name] && errors[name]}
                    multiple={multiple}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: multiple ? 'auto' : 36,
                        fontSize: '14px',
                        border: '1px solid',
                        lineHeight: 1.5,
                        fontWeight: 400,
                        borderColor: error ? theme.palette.error[700] : '#E2E8F0',
                        color: (multiple ? value.length : value) ? '#64748B' : theme.palette.primary[40],
                        '&.Mui-disabled': { borderColor: theme.palette.gray[100] },
                        '& fieldset': { border: '0 !important' },
                        paddingTop: '0',
                        paddingBottom: '0',
                        textAlign: 'left',
                        '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                        },
                        ...sx,
                    }}
                    name={name}
                    onClick={onClick}
                    {...restProps}
                    MenuProps={{
                        PaperProps: {
                            sx: { maxHeight: 250, maxWidth: '500px' },
                        },
                        ...MenuProps,
                    }}
                >
                    <MenuItem sx={{ color: '#94a3b8', fontSize: '14px', textAlign: 'center' }} value="">
                        {defaultValue}
                    </MenuItem>
                    {!options?.length && (
                        <MenuItem
                            disabled
                            sx={{
                                color: '#64748B',
                                fontSize: '14px',
                                border: '1px solid #F1F5F9',
                                textAlign: 'left',
                            }}
                            value=""
                        >
                            {noDataLabel || defaultValue}
                        </MenuItem>
                    )}
                    {!loading ? (
                        options?.map((option) => {
                            const isString = typeof option === 'string';
                            const value = isString ? option?.trim() : option?.value?.toString()?.trim();
                            return (
                                <MenuItem
                                    value={value}
                                    sx={{
                                        color: '#64748B',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        fontSize: '14px',
                                        height: '44px',
                                        borderTop: '1px solid #F1F5F9',
                                        borderLeft: '1px solid #F1F5F9',
                                        borderRight: '1px solid #F1F5F9',
                                        borderBottom: '0.5px solid #F1F5F9',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        '&.Mui-selected': {
                                            backgroundColor: '#0E2C66',
                                            color: '#ffffff',
                                            '&:hover': {
                                                backgroundColor: '#0E2C66',
                                                color: '#ffffff',
                                            },
                                        },
                                    }}
                                    key={value}
                                >
                                    {type === 'checkbox' && (
                                        <Radio
                                            checked={value === newValue}
                                            value="a"
                                            name="radio-buttons"
                                            sx={{ height: '30px' }}
                                            size="small"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    )}
                                    {isString ? option : option.name}
                                </MenuItem>
                            );
                        })
                    ) : (
                        <MenuItem
                            disabled
                            sx={{ color: theme.palette.gray[400], fontSize: '14px', textAlign: 'center' }}
                            value=""
                        >
                            Loading.......
                        </MenuItem>
                    )}
                    {custom &&
                        custom?.map((item) => {
                            return (
                                <MenuItem
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        paddingTop: '0',
                                        paddingBottom: '0',
                                        textAlign: 'center',
                                    }}
                                    value={item.value}
                                    key={item.value}
                                >
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
            {touched[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>}
        </>
    );
};

export default VendorFormSelect;
