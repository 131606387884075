import React from 'react';
import { TableBody } from '@mui/material';

// core components
import AppTableRow from './AppTableRow';
import EmptyTable from './EmptyTable';
import LoadingTable from './LoadingTable';

const AppTableBody = (props) => {
    const {
        columns,
        data,
        sorter,
        emptyStateText,
        loading,
        rowSpacing,
        clickableRow,
        onRowClick,
        rowBorder,
        implementer,
        tooltip,
        colored_cell_color,
    } = props;
    const sortedData = sorter ? data?.sort(sorter) : data;

    return (
        <TableBody>
            {loading ? (
                <LoadingTable />
            ) : sortedData?.length ? (
                sortedData?.map((row, index) => {
                    return clickableRow ? (
                        <AppTableRow
                            columns={columns}
                            row={row}
                            key={index}
                            rowSpacing={rowSpacing}
                            clickableRow={() => clickableRow(row)}
                            onRowClick={() => onRowClick(row)}
                            implementer={implementer}
                            colored_cell_color={colored_cell_color}
                            tooltip={tooltip}
                        />
                    ) : (
                        <AppTableRow
                            columns={columns}
                            row={row}
                            key={row?.id || index}
                            rowSpacing={rowSpacing}
                            rowBorder={rowBorder}
                            implementer={implementer}
                            colored_cell_color={colored_cell_color}
                            tooltip={tooltip}
                        />
                    );
                })
            ) : (
                <EmptyTable description={emptyStateText} />
            )}
        </TableBody>
    );
};

export default AppTableBody;
