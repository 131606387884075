import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import printJs from 'print-js';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export function setToken(userToken) {
    const domain_name = document?.location?.host?.substring(document?.location?.host?.indexOf('.') + 1);
    const domain = process.env.NODE_ENV === 'production' ? `domain=.${domain_name};` : '';
    const d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expires = `expires=${d.toUTCString()}`;
    document.cookie = `token=${userToken};${expires};${domain}path=/`;
}

export function setUserData(userData) {
    typeof window !== 'undefined' ? localStorage.setItem('userData', userData) : false;
}

export function setDomain() {
    const domain_name = document?.location?.host?.substring(document?.location?.host?.indexOf('.') + 1);
    const domain = process.env.NODE_ENV === 'production' ? `domain=.${domain_name};` : '';
    const d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expires = `expires=${d.toUTCString()}`;
    document.cookie = `domain=${domain_name};${expires};${domain}path=/`;
}

export function setType(key, userRole) {
    const domain_name = document?.location?.host?.substring(document?.location?.host?.indexOf('.') + 1);
    const domain = process.env.NODE_ENV === 'production' ? `domain=.${domain_name};` : '';
    const d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=${userRole};${expires};${domain}path=/`;
}

export function getUserToken(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export function getDomain(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export function getType(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';

    // let tokenString = JSON.parse(localStorage.getItem(key));

    // return tokenString;
}

export function endSession(token, type, role, id, org_id, mfa) {
    sessionStorage.clear();
    const domain_name = document?.location?.host?.substring(document?.location?.host?.indexOf('.') + 1);
    const domain = process.env.NODE_ENV === 'production' ? `domain=.${domain_name};` : '';
    const date = new Date();
    date.setTime(date.getTime() + 0 * 0 * 0 * 60 * 1000);
    let expires = `expires=${date.toUTCString()}`;
    document.cookie = `token=${JSON.stringify(token)};${expires};${domain}path=/`;
    document.cookie = `type=${JSON.stringify(type)};${expires};${domain}path=/`;
    document.cookie = `role=${JSON.stringify(role)};${expires};${domain}path=/`;
    document.cookie = `id=${JSON.stringify(id)};${expires};${domain}path=/`;
    document.cookie = `org_id=${JSON.stringify(org_id)};${expires};${domain}path=/`;
    document.cookie = `mfa=${JSON.stringify(mfa)};${expires};${domain}path=/`;
}

export function clearBrowserStorage() {
    sessionStorage.clear();
}

export function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    document.cookie = cname + '=' + cvalue + ';' + ';path=/';
    localStorage.setItem('accepted', true);
}
export const CurrencySign = '₦';

export const LOGGER = (key, value, isProd = process.env.NODE_ENV === 'production') => {
    if (isProd) return;

    // eslint-disable-next-line no-console
    console.log(key, value);
};

export const formatAmount = (num) => {
    if (num) {
        const initial = parseFloat(num).toFixed(2);
        return initial.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
};

export const formatDateObjectHandler = (dateObj, format = 'YYYY-MM-DD') => {
    if (!dateObj) {
        return undefined;
    }
    if (!moment.isMoment(dateObj)) {
        return moment(dateObj).format(format);
    }
    return dateObj.format(format);
};

export const truncateText = (string = '', length = '20', ending = '...') => {
    if (!(string && length)) {
        return;
    }
    return length > string.length ? string : string.substring(0, length - ending.length) + ending;
};

export const formatSentence = (string = '') => {
    return string ? string.slice(0, 1)?.toUpperCase() + string.slice(1) : '';
};

export const GetFromNowDate = (dateValue, stringFormat, fromNow, format = 'DD-MM-YYYY') => {
    if (moment(dateValue, stringFormat).isValid()) {
        if (fromNow) {
            return moment(dateValue).fromNow();
        }
        return moment(dateValue, stringFormat).format(format);
    }
    return '';
};
export const GetMonthFormatDate = (dateValue, stringFormat, format = 'MMMM DD, YYYY') => {
    if (moment(dateValue, stringFormat).isValid()) {
        return moment(dateValue, stringFormat).format(format);
    }
    return '';
};

export const GetTimeFormatDate = (dateValue, stringFormat, format = 'HH:mm:ss') => {
    if (moment(dateValue, stringFormat).isValid()) {
        return moment(dateValue, stringFormat).format(format);
    }
    return '';
};

export const getDaysFromDates = (firstDate, secondDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.round(Math.abs((new Date(secondDate) - new Date(firstDate)) / oneDay));
};

export const getFileExtension = (filename) => {
    return filename?.split('.').splice(-1);
};

export const keyEncoding = 'x'.repeat(15);

export const clientIsOnline = window.navigator.onLine;

export const riskLevelEvaluation = (value) => {
    let level = '';
    if (value <= 4) {
        level = 'Low';
    } else if (value >= 5 && value <= 10) {
        level = 'Medium';
    } else if (value >= 12) {
        level = 'High';
    }
    return level;
};
export const by3riskLevel = (value) => {
    let level = '';
    if (value === 1 || value <= 2) {
        level = { type: 'Low', color: '#0B885A' };
    } else if (value >= 3 && value <= 4) {
        level = { type: 'Medium', color: '#F2C021' };
    } else if (value >= 6) {
        level = { type: 'High', color: '#DE3730' };
    }
    return level;
};

export const by3riskValue = (value) => {
    let level = '';
    if (value === 1) {
        level = { type: 'Low', color: '#0B885A' };
    } else if (value === 2) {
        level = { type: 'Medium', color: '#F2C021' };
    } else if (value === 3) {
        level = { type: 'High', color: '#DE3730' };
    }
    return level;
};

export const by5riskValue = (value) => {
    let level = '';
    if (value === 1) {
        level = { type: 'Very Low', color: '#0B885A' };
    } else if (value === 2) {
        level = { type: 'Low', color: '#F2C021' };
    } else if (value === 3) {
        level = { type: 'Medium', color: '#DE3730' };
    } else if (value === 4) {
        level = { type: 'High', color: '#DE3730' };
    } else if (value === 5) {
        level = { type: 'Very High', color: '#DE3730' };
    }
    return level;
};

export const by5riskLevel = (value) => {
    let level = '';
    if (value === 1 || value <= 6) {
        level = { type: 'Low', color: '#0B885A' };
    } else if (value >= 7 && value <= 14) {
        level = { type: 'Medium', color: '#F2C021' };
    } else if (value >= 14) {
        level = { type: 'High', color: '#DE3730' };
    }
    return level;
};

export const by3riskLevelEvaluation = (value) => {
    let level = '';
    if (value === 1) {
        level = 'Low';
    } else if (value === 2) {
        level = 'Medium';
    } else if (value === 3) {
        level = 'High';
    }
    return level;
};

export function checkTag(tags, standard) {
    const tagsArray = tags?.split(',').map((item) => item?.trim());

    return tagsArray?.[0] === standard;
}

export const getOptionLabel = (doc) => {
    const label = `${
        doc?.tags === 'pcidss' || checkTag(doc?.tags, 'pcidss')
            ? doc?.document_title ||
              doc?.ndpr_title ||
              doc?.iso_title ||
              doc?.soc2_title ||
              doc?.criteria_title ||
              doc?.continuity_iso_title ||
              doc?.card4_title
            : doc?.tags === 'ndpr' || checkTag(doc?.tags, 'ndpr')
            ? doc?.ndpr_title ||
              doc?.iso_title ||
              doc?.soc2_title ||
              doc?.criteria_title ||
              doc?.continuity_iso_title ||
              doc?.card4_title ||
              doc?.document_title
            : doc?.tags === 'iso27001' || checkTag(doc?.tags, 'iso27001')
            ? doc?.iso_title ||
              doc?.ndpr_title ||
              doc?.soc2_title ||
              doc?.criteria_title ||
              doc?.continuity_iso_title ||
              doc?.card4_title ||
              doc?.document_title
            : doc?.tags === 'soc2' || checkTag(doc?.tags, 'soc2')
            ? doc?.soc2_title ||
              doc?.ndpr_title ||
              doc?.iso_title ||
              doc?.criteria_title ||
              doc?.continuity_iso_title ||
              doc?.card4_title ||
              doc?.document_title
            : doc?.tags === 'blockchain'
            ? doc?.ndpr_title ||
              doc?.iso_title ||
              doc?.soc2_title ||
              doc?.criteria_title ||
              doc?.continuity_iso_title ||
              doc?.card4_title ||
              doc?.document_title
            : doc?.tags === 'continuity' || checkTag(doc?.tags, 'continuity')
            ? doc?.continuity_iso_title ||
              doc?.ndpr_title ||
              doc?.iso_title ||
              doc?.soc2_title ||
              doc?.criteria_title ||
              doc?.card4_title ||
              doc?.document_title
            : doc?.tags === 'card4' || checkTag(doc?.tags, 'card4')
            ? doc?.card4_title ||
              doc?.ndpr_title ||
              doc?.iso_title ||
              doc?.soc2_title ||
              doc?.criteria_title ||
              doc?.continuity_iso_title ||
              doc?.document_title
            : doc?.tags === 'kdpa' || checkTag(doc?.tags, 'kdpa')
            ? doc?.kdpa_title ||
              doc?.iso_two_title ||
              doc?.card4_title ||
              doc?.ndpr_title ||
              doc?.iso_title ||
              doc?.soc2_title ||
              doc?.criteria_title ||
              doc?.continuity_iso_title ||
              doc?.document_title
            : doc?.tags === 'gdpa' || checkTag(doc?.tags, 'gdpa')
            ? doc?.gdpa_title ||
              doc?.kdpa_title ||
              doc?.iso_two_title ||
              doc?.card4_title ||
              doc?.ndpr_title ||
              doc?.iso_title ||
              doc?.soc2_title ||
              doc?.criteria_title ||
              doc?.continuity_iso_title ||
              doc?.document_title
            : doc?.tags === 'isms' || checkTag(doc?.tags, 'isms')
            ? doc?.iso_two_title ||
              doc?.card4_title ||
              doc?.ndpr_title ||
              doc?.iso_title ||
              doc?.soc2_title ||
              doc?.criteria_title ||
              doc?.continuity_iso_title ||
              doc?.document_title
            : doc?.tags === 'cii' || checkTag(doc?.tags, 'cii')
            ? doc?.cii_sector_title
            : null
    } (${doc?.tags === 'continuity' ? 'iso22301' : doc?.tags === 'isms' ? 'iso27001 - 2022' : doc?.tags})`;
    return label;
};

export const objStandardProperties = (tag, groupIDs, parent_id) => {
    let groupID, docTitleLabel, docDescriptionLabel, groupIdLabel, parentIdLabel, parentID;

    if (tag === 'pcidss') {
        groupID = groupIDs.sub_requirement_no;
        groupIdLabel = 'requirement_sub_no';
        parentID = parent_id.parent_requirement;
        parentIdLabel = 'requirement_id';
        docTitleLabel = 'document_title';
        docDescriptionLabel = 'document_description';
    } else if (tag === 'ndpr') {
        groupID = groupIDs.category;
        parentID = groupID;
        parentIdLabel = 'category';
        docTitleLabel = 'ndpr_title';
        docDescriptionLabel = 'ndpr_document_description';
    } else if (tag === 'iso27001') {
        groupID = groupIDs.annex;
        parentID = groupID;
        parentIdLabel = 'annex';
        docTitleLabel = 'iso_title';
        docDescriptionLabel = 'iso_description';
    } else if (tag === 'isms') {
        groupID = groupIDs.annex;
        parentID = groupID;
        parentIdLabel = 'annex_two';
        docTitleLabel = 'iso_two_title';
        docDescriptionLabel = 'iso_two_description';
    } else if (tag === 'soc2') {
        groupID = groupIDs.sub_control;
        groupIdLabel = 'sub_control';
        parentID = parent_id.common_control;
        parentIdLabel = 'common_control';
        docTitleLabel = 'soc2_title';
        docDescriptionLabel = 'soc2_description';
    }

    // pcidss, ndpr, iso, soc2
    // const getOptionLabel = (doc) => {
    //     const label = `${
    //         tag === 'pcidss'
    //             ? doc?.ndpr_title || doc?.iso_title || doc?.soc2_title
    //             : tag === 'ndpr'
    //             ? doc?.iso_title || doc?.soc2_title || doc?.document_title
    //             : tag === 'iso27001'
    //             ? doc?.soc2_title || doc?.document_title || doc?.ndpr_title
    //             : tag === 'soc2'
    //             ? doc?.document_title || doc?.ndpr_title || doc?.iso_title
    //             : null
    //     } (${doc?.tags})`;
    //     return label;
    // };

    return {
        groupID,
        docTitleLabel,
        docDescriptionLabel,
        groupIdLabel,
        parentIdLabel,
        getOptionLabel,
        parentID,
    };
};

export const numExtraction = (str = '') => {
    const matches = str?.match(/[\d.]+/);

    return matches ? parseFloat(matches[0]) : 0;
};

export const generateSortPoint = (title, tag) => {
    if (tag === 'pcidss') {
        return title;
    } else if (tag === 'ndpr') {
        return title;
    } else if (tag === 'iso27001') {
        const titleArr = title.split('.');
        return numExtraction(titleArr[titleArr.length - 1]);
    }
};

export const releaseDateFormat = (date) => {
    return moment(date).format('MMMM, DD, YYYY');
};
export const vendorResponseRating = (value) => {
    if (value >= 0 && value <= 24) {
        return 'FAIL';
    } else if (value >= 25 && value <= 49) {
        return 'POOR';
    } else if (value >= 50 && value <= 79) {
        return 'GOOD';
    } else if (value >= 80 && value <= 100) {
        return 'EXCELLENT';
    } else {
        return null;
    }
};

export const capitalizeFirstLetter = (string) => {
    return string[0]?.toUpperCase() + string.slice(1);
};

export const removeNullFields = (obj, extraField) => {
    const filteredObject = {};
    for (let field in obj) {
        if (obj[field] !== null && field !== extraField) filteredObject[field] = obj[field];
    }
    return filteredObject;
};
export const removeSpaces = (string) => {
    if (!string) return '';
    return string.replace(/\s+/g, '');
};

export const AVAILABLE_STANDARDS = [
    'PCIDSS',
    'NDPR',
    'ISO27001',
    'ISO27001-2022',
    'ISO27017+ISO27032',
    'SOC2',
    'SOC2TYPE2',
    'BLOCKCHAIN',
    'ISO22301',
    'PCIDSS4.0',
    'KDPA',
    'GDPA',
    'CII',
    'PCISLC',
    'ISO9001',
    'ISO37301',
];

export function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export const handlePaywall = () => {
    // history.push('/paywall');
};

export const isFullFunctionPlan = (plan) => {
    return ['main', 'custom'].includes(plan);
};

export const answeredQuestionsPercentage = (answeredQuestionsScore, totalQuestions) => {
    let result = Math.floor((answeredQuestionsScore / totalQuestions) * 100);

    return result;
};

export const copierHelper = (text) => {
    if (text) {
        navigator.clipboard.writeText(text);
        toast.success('Copied Successfully');
    } else {
        toast.success('Copied Successfully');
    }
};
export const generatePFDReport = async (array_ids) => {
    // const array_ids = ['page1', 'page2', 'page3'];
    const pages = [];
    for (let i = 0; i < array_ids?.length; i++) {
        const pageElement = document.getElementById(array_ids[i]);
        const page = await html2canvas(pageElement);
        pages.push(page);
    }
    const pdf = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        hotfixes: ['px_scaling'],
        putOnlyUsedFonts: true,
        compress: true,
    });
    pages.forEach((page, i) => {
        pdf.addImage({
            imageData: page,
            format: 'JPEG',
            x: 0,
            y: 0,
            width: 780,
            height: 1200,
            compression: 'MEDIUM',
        });
        if (i < pages.length - 1) {
            pdf.addPage();
        }
    });
    pdf.save('ciso-report.pdf');
};
export const pdfWithPrintJs = (printable, documentTitle, type = 'image') => {
    try {
        html2canvas(document.getElementById(printable), {
            logging: true,
            allowTaint: true,
            letterRendering: 1,
            useCORS: true,
            backgroundColor: 'white',
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 0.75);
            const config = {
                type,
                documentTitle,
                printable: imgData,
                base64: true,
            };
            printJs(config);
        });
    } catch (e) {
        console.log('pdfWithPrintJs', e);
        console.log('Failed!', 'PDF generation failed.\n Page might be too large.\n', 'error');
    }
};

export const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);
    let n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};
export const viewFile = (url) => {
    window.open(url, '_blank');
};
export const parseQuery = (queryString) => {
    const query = new URLSearchParams(queryString).entries();
    const queryObj = {};
    for (const [key, value] of query) {
        queryObj[key] = value;
    }
    return queryObj;
};
function getOffset(el) {
    var rect = el.getBoundingClientRect();
    return {
        x: rect.left + (rect.width + 52) / 2 - 275,
        y: rect.top + rect.height / 2 - 90,
    };
}

export function ConnectFn(div1, div2, color, thickness) {
    var lineElements = document.querySelectorAll('.line-element');
    if (lineElements.length) {
        lineElements.forEach((lineElement) => {
            const lineClassList = lineElement.classList;
            const startingPoint = div1.classList;
            if (startingPoint[1].length && startingPoint[1].replace('-IR', '') === lineClassList[1]) {
                var off1 = getOffset(div1); // Use getOffset to get the center offset
                var off2 = getOffset(div2); // Use getCenterOffset to get the center offset
                // distance
                var length = Math.sqrt((off2.x - off1.x) * (off2.x - off1.x) + (off2.y - off1.y) * (off2.y - off1.y));
                // center
                var cx = (off1.x + off2.x) / 2 - length / 2;
                var cy = (off1.y + off2.y) / 2 - thickness / 2;
                // angle
                var angle = Math.atan2(off1.y - off2.y, off1.x - off2.x) * (180 / Math.PI);
                const lineArrow = document.querySelector(`.${lineClassList[1]}`);
                lineArrow.style.display = 'block';
                lineArrow.style.padding = '0px';
                lineArrow.style.margin = '0px';
                lineArrow.style.height = `${thickness}px`;
                lineArrow.style.backgroundColor = `${color}`;
                lineArrow.style.lineHeight = `1px`;
                lineArrow.style.position = 'absolute';
                lineArrow.style.left = `${cx}px`;
                lineArrow.style.top = `${cy}px`;
                lineArrow.style.width = `${length - 40}px`;
                lineArrow.style.MozTransform = `rotate(${angle}deg)`;
                lineArrow.style.webkitTransform = `rotate(${angle}deg)`;
                lineArrow.style.transform = `rotate(${angle}deg)`;
                lineArrow.style.animation = 'line 2s infinite';
            }
        });
    }
}

export function checkTruthyValues(obj) {
    const values = Object.values(obj);

    const allTrue = values.every((value) => value === true);
    const someTrue = values.some((value) => value === true);
    const noneTrue = values.every((value) => value === false);

    return { allTrue, someTrue, noneTrue };
}

export function checkAnyTrue(obj) {
    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key) && obj[key] === true) {
            return true;
        }
    }
    return false;
}

export function checkAllAndSomeTrue(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return false;
    }

    return obj.allTrue === true && obj.someTrue === true;
}
export function typewriterEffect(textToType, interkeyStrokeDurationInMs) {
    const [currentPosition, setCurrentPosition] = useState(0);

    useEffect(() => {
        const previousInterval = setInterval(() => {
            setCurrentPosition((value) => value + 1);
        }, [interkeyStrokeDurationInMs]);
        return () => {
            clearInterval(previousInterval);
        };
    }, [interkeyStrokeDurationInMs]);

    return <div>{textToType?.substring(0, currentPosition)}</div>;
}

export const getCompliancePercentage = (dashboardResult, standard) => {
    let percentage;
    if (standard === 'pcidss') {
        percentage = dashboardResult?.pci_percentage;
    } else if (standard === 'ndpr') {
        percentage = dashboardResult?.ndpr_percentage;
    } else if (standard === 'soc2') {
        percentage = dashboardResult?.soc2_percentage;
    } else if (standard === 'iso27001') {
        percentage = dashboardResult?.iso_percentage;
    } else if (standard === 'sslc') {
        percentage = dashboardResult?.sslc_percentage;
    } else if (standard === 'iso22301') {
        percentage = dashboardResult?.continuity_percentage;
    } else if (standard === 'kdpa') {
        percentage = dashboardResult?.kdpa_percentage;
    } else if (standard === 'gdpa') {
        percentage = dashboardResult?.gdpa_percentage;
    } else if (standard === 'iso27001-2022') {
        percentage = dashboardResult?.iso_two_percentage;
    } else if (standard === 'iso9001') {
        percentage = dashboardResult?.iso9001_percentage;
    } else if (standard === 'iso37301') {
        percentage = dashboardResult?.iso37301_percentage;
    } else if (standard === 'cii') {
        percentage = dashboardResult?.cii_percentage;
    } else if (standard === 'pcidss4.0') {
        percentage = dashboardResult?.card4_percentage;
    } else if (standard === 'blockchain') {
        percentage = dashboardResult?.blockchain_percentage;
    } else if (standard === 'iso27017+iso27032') {
        percentage = dashboardResult?.continuity_percentage;
    }
    return percentage || 0;
};

export const getComplianceSummary = (dashboardResult, standard) => {
    let percentage;
    if (standard === 'pcidss') {
        percentage = dashboardResult?.pci_percentage;
    } else if (standard === 'ndpr') {
        percentage = dashboardResult?.ndpr_percentage;
    } else if (standard === 'soc2') {
        percentage = dashboardResult?.soc2_percentage;
    }
    return percentage;
};

export const URLKeys = [
    'url',
    'pentestscan',
    'report',
    'executive',
    'detailed',
    'asvscan',
    'file_field',
    'file',
    'response_file',
    'doc_file',
    'reason_document',
    'spoolreport',
    'merchant_signature',
    'certification',
    'default',
    'document',
];
export const userGuideDashboard = [
    {
        id: 1,
        isNew: false,
        title: 'Getting started with Smartcomply',
        description:
            'Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. Scans This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. TEsting lorem ipsum Fighting lorem ipsum',
    },
    {
        id: 2,
        isNew: false,
        title: 'How to perform penetration testing',
        description:
            'Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. Scans This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. TEsting lorem ipsum Fighting lorem ipsum',
    },
    {
        id: 3,
        isNew: false,
        title: 'What is PCI scan?',
        description:
            'Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. Scans This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. TEsting lorem ipsum Fighting lorem ipsum',
    },
    {
        id: 4,
        isNew: true,
        title: 'What compliance standard should I opt for?',
        description:
            'Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. Scans This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. TEsting lorem ipsum Fighting lorem ipsum',
    },
];

export const userGuidePentest = [
    {
        id: 1,
        isNew: false,
        title: 'How to perform penetration testing',
        description:
            'Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. Scans This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. TEsting lorem ipsum Fighting lorem ipsum',
    },
    {
        id: 2,
        isNew: false,
        title: 'What happens if my scan fails',
        description:
            'Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. Scans This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. TEsting lorem ipsum Fighting lorem ipsum',
    },
    {
        id: 3,
        isNew: true,
        title: 'Is there a third party involvement in pentesting',
        description:
            'Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. Welcome to our Trust Page for [Company name] - your entry point into our uncompromising dedication to compliance, data security, and privacy. Here, you may look at our thorough compliance documents, get answers to privacy and security-related commonly asked questions, and learn more about our strong security procedures. Scans This page is created to give you the details and assurances you require to feel confident in our abilities to protect your data since we believe in being open and fostering trust with our clients. TEsting lorem ipsum Fighting lorem ipsum',
    },
];

export const Plans = [
    {
        name: 'You’re on Free',
        description: 'This is not a plan for you. Trust me',
        employee: '1 employee',
        feature_phrase: 'Limited to:',
        plan: 'free',
        features: [
            {
                feat_name: '1 Vendor Management',
                allow: true,
            },
            {
                feat_name: 'Vulnerability Scanner',
                allow: true,
            },
            {
                feat_name: 'Automated Scans',
                allow: true,
            },
            {
                feat_name: 'On-Premises Server Monitoring',
                allow: true,
            },
            {
                feat_name: 'Penetration Test',
                allow: false,
            },
            {
                feat_name: 'PCI Scans',
                allow: false,
            },
            {
                feat_name: 'Background Check',
                allow: false,
            },
            {
                feat_name: 'Vendor Response Bot',
                allow: false,
            },
        ],
    },
    {
        name: 'Start up',
        description: 'Perfect for small businesses and startups',
        employee: '1 - 50 employees',
        feature_phrase: 'Get started with:',
        plan: 'Start up',
        id: 2,
        features: [
            {
                feat_name: 'Penetration Test',
                allow: true,
            },
            {
                feat_name: 'PCI/ASV Scans',
                allow: true,
            },
            {
                feat_name: 'Vendor Management',
                allow: true,
            },
            {
                feat_name: 'Vulnerability Scanner',
                allow: true,
            },
            {
                feat_name: 'Automated Scans',
                allow: true,
            },
            {
                feat_name: 'On-Premises Server Monitoring',
                allow: true,
            },
            {
                feat_name: 'Audit/Auditor Collaboration',
                allow: true,
            },
            {
                feat_name: 'Pre Security Assessment',
                allow: true,
            },
        ],
    },
    {
        name: 'Growth',
        description: 'Good for businesses willing to scale',
        employee: '51 - 100 employees',
        feature_phrase: 'All the goodness of Start-up plan, with more range plus:',
        plan: 'Growth',
        id: 5,
        features: [
            {
                feat_name: 'Ciso report',
                allow: true,
            },
            {
                feat_name: 'Dedicated support',
                allow: true,
            },
            {
                feat_name: 'Trust support',
                allow: true,
            },
            {
                feat_name: 'In-House Auditor Support',
                allow: true,
            },
        ],
    },
    {
        name: 'Enterprise',
        description: 'Perfect for super large businesses',
        employee: '100 - Above employees',
        feature_phrase: 'All the beauty of Growth, unlimited and:',
        plan: 'Enterprise',
        id: 3,
        features: [
            {
                feat_name: 'Artificial intelligence ROC',
                allow: true,
            },
            {
                feat_name: 'Vendor response bot',
                allow: true,
            },
        ],
    },
    {
        name: 'Custom',
        description: 'Perfect for small or large',
        employee: 'Flexible',
        feature_phrase: 'Be the boss',
        plan: 'Custom',
        features: [
            {
                feat_name: 'Customize features suitable for your organization from the list of feature we have.',
                allow: true,
            },
        ],
    },
];

export const urlKeys = [
    'url',
    'pentestscan',
    'report',
    'executive',
    'detailed',
    'asvscan',
    'file_field',
    'file',
    'response_file',
    'doc_file',
    'reason_document',
    'spoolreport',
    'merchant_signature',
    'certification',
    'default',
    'document',
    'new_document',
];
export function getSize(size) {
    if (size < 1024) {
        return `${size.toFixed(2)} bytes`;
    } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} KB`;
    } else if (size < 1024 * 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    } else {
        return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
}
