import React, { useState, useEffect, memo } from 'react';
import { Box, Button, Modal, Typography, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';
import NoRiskTrend from '../../../../../assets/img/icons/No-risk-trend.svg';
import NoQuestionnaire from '../../../../../assets/img/icons/No-questionnaire-history.svg';
// import { useTranslation } from 'react-i18next';
// import { formatDate } from '../../utils/utils';
import { connect } from 'react-redux';
import { GetQuestionnaireHistory } from 'store/actions/vendorActions';
import { toast } from 'react-toastify';
import { Row } from 'antd';
import CircularLoader from 'components/Loaders/CircularLoader';
import moment from 'moment';
import QuestionnaireHistoryItem from './components/QuestionnaireHistoryItem';
import { DeleteQuestionnaire } from 'store/actions/vendorActions';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const DeleteModal = (props) => {
    const { open, handleClose, DeleteQuestionnaire, id, selected } = props;

    const [deleting, setDeleting] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation('vendorManagement');

    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeleteQuestionnaire(selected?.id, id);
        setDeleting(false);

        if (res?.success) {
            toast.success('Successfully deleted a Vendor.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="vendor-delete-modal-title"
            aria-describedby="vendor-delete-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'white',
                    boxShadow: 24,
                    borderRadius: 2,
                    outline: 'none',
                }}
            >
                {/* Modal Content */}
                <Box
                    sx={{
                        p: 3,
                        textAlign: 'center',
                        background: '#fff',
                    }}
                >
                    <Typography
                        id="vendor-delete-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            fontWeight: 600,
                            fontSize: '18px',
                            color: theme.palette.gray[600],
                        }}
                    >
                        {t('deleteQuestionnaireModal.title')} {selected?.name}?
                    </Typography>

                    <Typography
                        id="vendor-delete-modal-description"
                        sx={{
                            mt: 2,
                            color: theme.palette.gray[500],
                            fontSize: '14px',
                        }}
                    >
                        {t(`deleteQuestionnaireModal.subtitle`)}
                    </Typography>
                </Box>

                {/* Footer with Buttons */}
                <Box
                    sx={{
                        bgcolor: '#F8FAFC',
                        py: 3,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        borderTop: '1px solid #E5E7EB',
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.gray[900],
                            background: '#fff',
                            border: '1px solid #E2E8F0',
                        }}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        loading={deleting}
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#F43F5E',
                            '&:hover': {
                                backgroundColor: '#E11D48',
                            },
                        }}
                    >
                        Delete
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    );
};

const VendorQuestionnaireHistory = memo(
    ({ DeleteQuestionnaire, GetQuestionnaireHistory, questionnaireData, details }) => {
        const theme = useTheme();
        const { t } = useTranslation('vendorManagement');
        const [loading, setLoading] = useState(false);
        const [modal, showModal] = useState(null);
        const [selected, setSelected] = useState({});

        // Fetch questionnaire history when the vendor ID changes
        useEffect(() => {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const res = await GetQuestionnaireHistory(details.id);

                    if (!res.success) {
                        throw new Error(res.message || 'Failed to fetch questionnaire history');
                    }
                } catch (error) {
                    toast.error(error.message || 'An error occurred while fetching data');
                } finally {
                    setLoading(false);
                }
            };

            if (details?.id) fetchData();
        }, [details.id, GetQuestionnaireHistory]);

        const handleDelete = (q) => {
            showModal('delete');
            setSelected(q);
        };

        // Prepare data for the risk trend chart
        const prepareRiskTrendData = () => {
            return {
                labels: questionnaireData.map((q) => {
                    const date = new Date(q.date_created);
                    return date.toLocaleString('default', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                }),
                datasets: [
                    {
                        label: t('questionnaireHistory.yText'),
                        data: questionnaireData?.map((q) => 100 - q.overall_score_percent), // Calculate risk level
                        borderColor: '#395BA9', // Line color
                        backgroundColor: 'rgba(173, 216, 230, 0.4)', // Light blue fill color
                        fill: true, // Fill the area under the line
                        tension: 0.4, // Smoothness of the line
                    },
                ],
            };
        };

        const riskTrendOptions = {
            plugins: {
                legend: { display: false },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const date = questionnaireData?.[context?.dataIndex]?.date_created;
                            return moment(date).format('MMM DD, YYYY');
                        },
                        title: function (context) {
                            const score = !isNaN(Number(context?.[0]?.raw)) ? context?.[0]?.raw : 0;
                            let risk = 'Low';
                            if (score >= 67) risk = 'High';
                            else if (score >= 34) risk = 'Medium';

                            return `${score?.toFixed()} :: ${risk} risk`;
                        },
                    },
                },
            },
            scales: {
                y: {
                    beginAtZero: true, // Important: Keep this false
                    max: 100,
                    reverse: false,
                    ticks: {
                        color: '#64748B',
                        font: {
                            family: 'Poppins',
                        },
                        callback: (value) => {
                            if (value === 100) return 'High'; // High at 100
                            if (value === 66) return 'Medium'; // Medium at 66
                            if (value === 33) return 'Low'; // Low at 33
                            return ''; // Hide other values
                        },
                        stepSize: 33,
                    },
                    grid: {
                        color: '#E2E8F0',
                    },
                    title: {
                        display: true,
                        text: 'Risk level',
                        color: '#64748B',
                        font: {
                            size: 14,
                            weight: 'bold',
                            family: 'Poppins',
                        },
                    },
                },
                x: {
                    ticks: {
                        color: '#64748B',
                        font: {
                            family: 'Poppins',
                        },
                        callback: (value) => {
                            // Format x-axis labels as month and year
                            const date = new Date(questionnaireData?.[value].date_created);
                            return date.toLocaleString('default', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                            });
                        },
                    },
                    grid: {
                        color: '#E2E8F0',
                    },
                },
            },
            maintainAspectRatio: true,
            aspectRatio: 18 / 9,
        };
        return loading ? (
            <Row justify="center">
                <CircularLoader size="large" />
            </Row>
        ) : (
            <Box sx={{ mt: 3 }}>
                <DeleteModal
                    open={modal === 'delete'}
                    handleClose={() => showModal(null)}
                    selected={selected}
                    DeleteQuestionnaire={DeleteQuestionnaire}
                    id={details?.id}
                />

                {/* Risk Trend Section */}
                <Box
                    sx={{
                        width: '100%',
                        aspectRatio: '3/2',
                        border: '1px solid #E2E8F0',
                        borderRadius: '8px',
                        p: '1rem',
                        mt: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.primary[500],
                                fontSize: '14px',
                                fontWeight: 600,
                                fontFamily: 'Poppins',
                            }}
                        >
                            Questionnaire Risk Trend
                        </Typography>
                    </Box>

                    {questionnaireData?.length > 0 ? (
                        <Box sx={{ height: '80%', position: 'relative', width: '100%' }}>
                            <Line data={prepareRiskTrendData()} options={riskTrendOptions} />
                        </Box>
                    ) : (
                        <div className="flex flex-col items-center gap-5 h-full justify-center">
                            <img src={NoRiskTrend} alt="No risk trend" />
                            <Typography sx={{ color: '#64748B', fontWeight: 500, textAlign: 'center' }}>
                                No Risk trend available for now
                            </Typography>
                        </div>
                    )}
                </Box>

                {/* Questionnaire History Section */}
                <Typography sx={{ fontWeight: '500', mt: 4, mb: 2, fontFamily: 'Poppins' }}>
                    Questionnaire History
                </Typography>

                {questionnaireData?.length > 0 ? (
                    questionnaireData?.map((questionnaire) => (
                        <QuestionnaireHistoryItem
                            questionnaire={questionnaire}
                            handleDelete={() => handleDelete(questionnaire)}
                            details={details}
                        />
                    ))
                ) : (
                    <div className="flex flex-col items-center gap-5 h-full justify-center pb-5 pt-2">
                        <img src={NoQuestionnaire} alt="No questionnaire history" />
                        <Typography
                            sx={{ color: '#64748B', fontWeight: 500, textAlign: 'center', fontFamily: 'Poppins' }}
                        >
                            No questionnaire history
                        </Typography>
                    </div>
                )}
            </Box>
        );
    }
);

const mapStateToProps = (state) => {
    return {
        questionnaireData: state?.vendorReducers?.questionnaire_history,
    };
};
export default connect(mapStateToProps, { GetQuestionnaireHistory, DeleteQuestionnaire })(VendorQuestionnaireHistory);
