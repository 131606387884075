import { IconButton, Stack } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetDownloadLink } from 'store/actions/generalActions';
import download from '../../../assets/img/download.svg';
import more from '../../../assets/img/more.svg';

// translations
import { useTranslation } from 'react-i18next';

const PentestTableActions = (props) => {
    const {
        row,
        openDownloadPentestReportModal,
        tableTab,
        openViewModal,
        // openAssignModal,
        openRequestActionModal,
        openChangeScheduleModal,
    } = props;

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    const theme = useTheme();

    // state
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadingLink, setLoadingLink] = useState(false); // Loading state for download link

    // translation
    const { t } = useTranslation('pentest');

    // functions
    const openMenu = (e) => setAnchorEl(e.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    const openReportModal = () => {
        closeMenu();
        openDownloadPentestReportModal(row?.id);
    };

    const handleOpenViewModal = () => {
        closeMenu();
        openViewModal('view', row);
    };

    const handleOpenRerunModal = () => {
        closeMenu();
        openRequestActionModal('rerun', row);
    };

    // const handleAssignPentestModal = () => {
    //     closeMenu();
    //     openAssignModal('assign', row);
    // };

    const handleChangeSchedule = () => {
        const payload = {
            schedule: row?.runschedule,
        };
        closeMenu();
        openChangeScheduleModal('view', payload, row?.id);
    };

    const getDownloadLink = async () => {
        if (row?.pentestscan) {
            setLoadingLink(true);
            const res = await GetDownloadLink('asvc_requests', 'pentest_request', row.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(row?.pentestscan, '_blank');
            }
        }
    };

    const menus = [
        {
            name: t('table.tableAction.viewDetails'),
            component: (
                <span
                    onClick={handleOpenViewModal}
                    disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
                    className={
                        row?.progress === 'running' || row?.progress === 'rerunning'
                            ? 'opacity-50 cursor-not-allowed pointer-events-none'
                            : ''
                    }
                    style={{ width: '179px' }}
                >
                    {t('table.tableAction.viewDetails')}
                </span>
            ),
            isAvailable: ['requested'],
        },
        {
            name: t('table.tableAction.changeSchedule'),
            component: (
                <span
                    onClick={handleChangeSchedule}
                    disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
                    className={
                        row?.progress === 'running' || row?.progress === 'rerunning'
                            ? 'opacity-50 cursor-not-allowed pointer-events-none'
                            : ''
                    }
                    style={{ width: '179px' }}
                >
                    {t('table.tableAction.changeSchedule')}
                </span>
            ),
            isAvailable: ['requested'],
        },
        // {
        //     name: t('table.tableAction.assign'),
        //     component: (
        //         <span
        //             onClick={handleAssignPentestModal}
        //             disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
        //             style={{ width: '179px' }}
        //         >
        //             {t('table.tableAction.assign')}
        //         </span>
        //     ),
        //     isAvailable: ['requested'],
        // },
        user_type !== 'auditor'
            ? {
                  name: t('table.tableAction.rerunTest'),
                  component: (
                      <span
                          onClick={handleOpenRerunModal}
                          disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
                          className={
                              row?.progress === 'running' || row?.progress === 'rerunning'
                                  ? 'opacity-50 cursor-not-allowed pointer-events-none'
                                  : ''
                          }
                          style={{ width: '179px' }}
                      >
                          {t('table.tableAction.rerunTest')}
                      </span>
                  ),
                  isAvailable: ['requested'],
              }
            : role !== 'auditor'
            ? {
                  name: t('table.tableAction.rerunTest'),
                  component: (
                      <span
                          onClick={handleOpenRerunModal}
                          disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
                          className={
                              row?.progress === 'running' || row?.progress === 'rerunning'
                                  ? 'opacity-50 cursor-not-allowed pointer-events-none'
                                  : ''
                          }
                          style={{ width: '179px' }}
                      >
                          {t('table.tableAction.rerunTest')}
                      </span>
                  ),
                  isAvailable: ['requested'],
              }
            : null,
        {
            name: t('table.tableAction.downloadReport'),
            component:
                row?.progress === 'running' || row?.progress === 'rerunning' ? (
                    <span style={{ width: '179px', opacity: 0.5 }}>{t('table.tableAction.downloadReport')}</span>
                ) : (
                    <span onClick={openReportModal} disabled={row?.progress === 'running'} style={{ width: '179px' }}>
                        {t('table.tableAction.downloadReport')}
                    </span>
                ),
            isAvailable: ['uploaded', 'requested'],
        },
    ];

    return (
        <Stack direction="row">
            {tableTab === 'uploaded' && (
                <IconButton
                    sx={{ color: theme.palette.gray[900] }}
                    onClick={getDownloadLink}
                    disabled={!row?.pentestscan || loadingLink}
                >
                    <img
                        style={{ opacity: !row?.pentestscan || loadingLink ? 0.5 : 1 }}
                        src={download}
                        className="cursor-pointer text-[#64748B] text-[14px] font-semibold"
                        alt="Download"
                    />
                </IconButton>
            )}
            {tableTab === 'requested' && (
                <img
                    src={more}
                    onClick={openMenu}
                    className="cursor-pointer text-[#64748B] text-[14px] font-semibold"
                    alt="More"
                />
            )}

            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeMenu}
                sx={{}}
                PaperProps={{
                    style: {
                        width: '179px',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        borderRadius: '2px',
                    },
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {menus?.map((menu, index) => {
                    const isFirst = index === 0;
                    const isLast = index === menus.length - 1;
                    return (
                        menu?.isAvailable?.includes(tableTab) && (
                            <MenuItem
                                style={{
                                    height: '44px',
                                    width: '179px',
                                    paddingLeft: '16px',
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    borderTop: '1px solid #F1F5F9',
                                    borderLeft: '1px solid  #F1F5F9',
                                    borderRight: '1px solid #F1F5F9',
                                    borderBottom: '0.5px solid #F1F5F9',
                                    marginBottom: isLast ? '-10px' : '0px',
                                    marginTop: isFirst ? '-10px' : '0px',
                                }}
                                key={menu.name}
                            >
                                {menu.component}
                            </MenuItem>
                        )
                    );
                })}
            </Menu>
        </Stack>
    );
};

export default PentestTableActions;
