import React from 'react';
import { useTheme } from '@mui/material/styles';
import { TableRow, Tooltip } from '@mui/material';

// core components
import AppTableCell from './AppTableCell';

const AppTableRow = (props) => {
    const theme = useTheme();
    const { columns, row, rowSpacing, clickableRow, onRowClick, rowBorder, implementer, tooltip, colored_cell_color } =
        props;
    const spacingBorderStyles = {
        borderTop: '1px solid ' + theme.palette.gray[20] + 80,
        borderBottom: '1px solid ' + theme.palette.gray[20] + 80,
        '&:first-of-type': {
            borderLeft: '1px solid ' + theme.palette.gray[20] + 80,
        },
        '&:last-of-type': {
            borderRight: '1px solid ' + theme.palette.gray[20] + 80,
        },
    };
    return (
        <>
            {row?.risk_level_alert ? (
                <Tooltip
                    title={tooltip}
                    placement="bottom-start"
                    arrow
                    sx={{ width: '100px', backgroundColor: '#000000 !important' }}
                >
                    <TableRow
                        sx={{
                            my: implementer ? 0 : 3,
                            boxShadow:
                                '-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                            backgroundColor: colored_cell_color,
                            px: 2,
                            cursor: clickableRow && clickableRow() ? 'pointer' : 'default',
                        }}
                        onClick={clickableRow && clickableRow() ? onRowClick : null}
                    >
                        {columns.map((column, index) => {
                            return (
                                <AppTableCell
                                    key={column?.title + row?.id + index}
                                    sx={{
                                        fontWeight: 400,
                                        color: theme.palette.gray[500],
                                        borderBottom: '1px solid ' + theme.palette.gray[100],
                                        border: rowBorder ? '1px solid ' + theme.palette.neutral[20] : 'none',
                                        padding: 1,
                                        pl: index === 0 ? 2 : 1,
                                        height: 'fit-content',
                                        ...(rowSpacing ? spacingBorderStyles : {}),
                                    }}
                                    align={column.align}
                                >
                                    {column.render(row)}
                                </AppTableCell>
                            );
                        })}
                    </TableRow>
                </Tooltip>
            ) : (
                <TableRow
                    sx={{
                        my: 3,
                        boxShadow:
                            '-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                        backgroundColor: theme.palette.white.main,
                        px: 2,
                        cursor: clickableRow && clickableRow() ? 'pointer' : 'default',
                    }}
                    onClick={clickableRow && clickableRow() ? onRowClick : null}
                >
                    {columns.map((column, index) => {
                        return (
                            <AppTableCell
                                key={column?.title + row?.id + index}
                                sx={{
                                    fontWeight: 400,
                                    color: theme.palette.gray[500],
                                    borderBottom: '1px solid ' + theme.palette.gray[100],
                                    border: rowBorder ? '1px solid ' + theme.palette.neutral[20] : 'none',
                                    padding: 1,
                                    pl: index === 0 ? 2 : 1,
                                    ...(rowSpacing ? spacingBorderStyles : {}),
                                }}
                                align={column.align}
                            >
                                {column.render(row)}
                            </AppTableCell>
                        );
                    })}
                </TableRow>
            )}
        </>
    );
};

export default AppTableRow;
