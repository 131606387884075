import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { all_standards, tabs } from './utils';
// import { MoreVert } from '@mui/icons-material';
// import { Divider, Menu, MenuItem } from '@mui/material';
import AppLinearProgress from 'components/new_components/AppLinearProgress';
// import FileIcon from 'assets/img/file_icon.png';
import { getCompliancePercentage } from 'utils';
import { useHistory } from 'react-router-dom';
import Carousel from 'components/new_components/carousel/Carousel';
import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import SmartIntel from 'components/new_components/SmartIntel';
import { useSmartIntelContext } from 'store/context/SmartIntelContextProvider';
import { ReactComponent as GreenTick } from 'assets/img/green-tick.svg';
import EmptyImage from 'assets/img/no-frameworks.svg';
import { GetDashboardCompliance } from 'store/actions/dashboardActions';

const Framwworks = () => {
    const [currentTab, setCurrentTab] = useState('All');
    const [search, setSearch] = useState('');
    // const [anchor, setAnchor] = useState(null);
    // const [currentTag, setCurrentTag] = useState('');
    // const [type, setType] = useState('');
    // const [openDrawer, setOpenDrawer] = useState(false);
    // const [currentData, setCurrentData] = useState(null);

    const dispatch = useDispatch();

    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const { dashboardResult } = useSelector((state) => state?.dashboardReducers);
    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const keywords = merchant_info?.intel_keywords?.['Compliance'];
    const { handleOpen } = useSmartIntelContext();

    const switch_on = merchant_info?.smart_intel;
    const router = useHistory();
    const gotoPage = (link) => {
        router.push(link);
    };

    const convertToLowerCase = (data) => {
        if (!Array.isArray(data) || !data?.length) return data;
        return data?.map((el) => el.toLowerCase());
    };

    const filtered_standards = convertToLowerCase(merchant_info?.standards)
        ?.split(',')
        ?.filter(
            (standard) => !convertToLowerCase(merchant_info?.merchant_completed_standards || []).includes(standard)
        );

    const disabled_standards = convertToLowerCase(merchant_info?.paid_standards?.split(',')).filter((item) => {
        return !convertToLowerCase(merchant_info?.standards.split(',')).includes(item);
    });
    const unsubscribed_standards = convertToLowerCase(all_standards).filter((item) => {
        return !convertToLowerCase(merchant_info?.paid_standards.split(',')).includes(item);
    });
    const completed_standards = merchant_info?.merchant_completed_standards
        ? convertToLowerCase(merchant_info?.merchant_completed_standards)
        : [];

    const sortByStatus = useCallback(
        (data) => {
            return data.sort((a, b) => {
                const isADisabled = disabled_standards.includes(a);
                const isBDisabled = disabled_standards.includes(b);

                const isACompleted = completed_standards.includes(a);
                const isBCompleted = completed_standards.includes(b);

                const isANotSubscribed = unsubscribed_standards.includes(a);
                const isBNotSubscribed = unsubscribed_standards.includes(b);

                // active standards (not disabled or unsubscribed or completed)
                if (
                    !isADisabled &&
                    !isANotSubscribed &&
                    !isACompleted &&
                    (isBDisabled || isBNotSubscribed || !isACompleted)
                )
                    return -1;
                if (
                    !isBDisabled &&
                    !isBNotSubscribed &&
                    !isBCompleted &&
                    (isADisabled || isANotSubscribed || !isBCompleted)
                )
                    return 1;

                // completed/compliant standards
                if (isACompleted && !isBCompleted) return -1;
                if (isBCompleted && !isACompleted) return 1;

                // disabled standards (should come before unsubscribed)
                if (isADisabled && !isBDisabled) return -1;
                if (isBDisabled && !isADisabled) return 1;

                // unsubscribed standards
                if (isANotSubscribed && !isBNotSubscribed) return 1;
                if (isBNotSubscribed && !isANotSubscribed) return -1;

                return 0;
            });
        },
        [disabled_standards, completed_standards, unsubscribed_standards]
    );

    const filteredItems = useMemo(() => {
        const data =
            currentTab === 'All'
                ? sortByStatus(all_standards)
                : currentTab === 'Undergoing compliance'
                ? filtered_standards
                : currentTab === 'Compliant'
                ? completed_standards
                : currentTab === 'Not subscribed'
                ? unsubscribed_standards
                : currentTab === 'Disabled'
                ? disabled_standards
                : [];

        return data.filter(
            (item) =>
                item?.toLowerCase()?.includes(search?.toLowerCase()) &&
                item?.toLowerCase() !== 'iso37301' &&
                item?.toLowerCase() !== 'soc2type2'
        );
    }, [currentTab, search]);

    useEffect(() => {
        const getDashboardResult = async () => {
            await dispatch(GetDashboardCompliance());
        };

        if (!dashboardResult) {
            getDashboardResult();
        }
    }, [dashboardResult]);

    const getStandardName = (standard) => {
        return standard === 'cii'
            ? 'CII DIRECTIVE'
            : standard === 'sslc'
            ? 'PCI SLC'
            : standard === 'soc2'
            ? 'SOC 2'
            : standard === 'pcidss4.0'
            ? 'PCI DSS 4.0'
            : standard === 'iso27001'
            ? 'ISO 27001'
            : standard === 'iso22301'
            ? 'ISO 22301'
            : standard === 'iso9001'
            ? 'ISO 9001'
            : standard === 'iso37301'
            ? 'ISO 37301'
            : standard === 'i/so27001-2022'
            ? 'ISO 27001 - 2022'
            : standard === 'iso27017+iso27032'
            ? 'ISO 27017 + ISO 27032'
            : standard;
    };

    function RenderTab() {
        return filteredItems?.length ? (
            <div className="grid grid-cols-3 max-lg:grid-cols-2 gap-5">
                {filteredItems?.map((standard, index) => {
                    const isDisabled = disabled_standards.includes(standard?.toLowerCase());
                    const isUndergoingCompliance = false;
                    const isNotSubscribed = unsubscribed_standards.includes(standard?.toLowerCase());
                    const cannotRedirect = isDisabled || isNotSubscribed;
                    const isCompliant = !cannotRedirect && completed_standards.includes(standard?.toLowerCase());

                    return (
                        <div
                            key={index}
                            className={`bg-white border-1 border-[#F1F5F9] rounded-md p-3  ${
                                cannotRedirect ? 'pointer-events-none' : 'cursor-pointer'
                            }`}
                            onClick={() =>
                                !cannotRedirect &&
                                gotoPage(
                                    `/merchant/compliance/frameworks/${
                                        standard === 'cii'
                                            ? 'cii_directive'
                                            : standard === 'sslc'
                                            ? 'pcislc'
                                            : standard === 'iso27001-2022'
                                            ? 'iso27001_2'
                                            : standard === 'iso27001+iso27032'
                                            ? 'iso27001+iso27032'
                                            : standard
                                    }`
                                )
                            }
                        >
                            <div className="flex justify-between items-center">
                                <p
                                    className={`text-[#475569] text-base font-semibold uppercase ${
                                        cannotRedirect ? 'opacity-60' : 'opacity-100'
                                    }`}
                                >
                                    {getStandardName(standard)}
                                </p>
                                {/* <>
                                            <div
                                                className="border border-[#E2E8F0] rounded cursor-pointer"
                                                onClick={openMenu}
                                            >
                                                <MoreVert />
                                            </div>
                                            <Menu
                                                anchorEl={anchor}
                                                open={Boolean(anchor)}
                                                onClose={closeMenu}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <MenuItem sx={{ fontSize: 12 }}>Spool Report</MenuItem>
                                                <Divider />
                                                <MenuItem sx={{ fontSize: 12 }}>Download Certificate</MenuItem>
                                            </Menu>
                                        </> */}
                            </div>
                            <p
                                className={`${
                                    cannotRedirect
                                        ? 'text-[#937300]'
                                        : isCompliant
                                        ? 'text-[#37A372]'
                                        : 'text-[#64748B]'
                                } text-[13px] font-medium mt-1 flex gap-1 items-center justify-start`}
                            >
                                {isCompliant ? <GreenTick className="w-4 h-4" /> : null}
                                {isDisabled
                                    ? 'Disabled'
                                    : isUndergoingCompliance
                                    ? 'Undergoing Compliance'
                                    : isNotSubscribed
                                    ? 'Not subscribed'
                                    : isCompliant
                                    ? 'Compliant'
                                    : 'Undergoing compliance'}
                            </p>
                            <div
                                key={index}
                                className={`mb-2 mt-[4rem]  ${cannotRedirect ? 'opacity-60' : 'opacity-100'}`}
                            >
                                <AppLinearProgress
                                    value={getCompliancePercentage(dashboardResult, standard)}
                                    sx={{ my: 1, color: '#395BA9' }}
                                />
                                <div
                                    className="flex justify-end items-center cursor-pointer"
                                    onClick={() =>
                                        !cannotRedirect &&
                                        gotoPage(
                                            `/merchant/compliance/frameworks/${
                                                standard === 'cii'
                                                    ? 'cii_directive'
                                                    : standard === 'sslc'
                                                    ? 'pcislc'
                                                    : standard === 'iso27001-2022'
                                                    ? 'iso27001_2'
                                                    : standard === 'iso27001+iso27032'
                                                    ? 'iso27001+iso27032'
                                                    : standard
                                            }`
                                        )
                                    }
                                >
                                    {/* <div className="flex items-center gap-1">
                                                <img src={FileIcon} alt="FileIcon" />
                                                <p className="text-[#475569] text-[11px] font-medium">
                                                    3/11{' '}
                                                    <span className="text-[#64748B] text-[11px] font-normal">
                                                        Control Objective
                                                    </span>
                                                </p>
                                            </div> */}
                                    <p className="text-[#64748B] text-[11px] font-semibold">
                                        {Math.ceil(getCompliancePercentage(dashboardResult, standard))}%{' '}
                                        <span className="text-[#64748B] text-[11px] font-normal">Done</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : (
            <div className="w-full flex flex-col items-center justify-center mx-auto gap-2">
                <img src={EmptyImage} alt="no frameworks" className="w-44 h-44 mb-3" />
                <h5 className="font-semibold text-[#1E293B] text-lg">No framework available</h5>
                {currentTab != 'Not subscribed' && currentTab !== 'Compliant' ? (
                    <p className="font-medium text-[#1E293B] text-sm">
                        <span
                            className="underline cursor-pointer text-[#395BA9]"
                            onClick={() => setCurrentTab('Not subscribed')}
                        >
                            Check out
                        </span>{' '}
                        our available frameworks
                    </p>
                ) : null}
            </div>
        );
    }

    const compliance_intel =
        switch_on && all_smart_intel?.Compliance
            ? Object?.values(all_smart_intel?.Compliance)
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];

    return (
        <SmartIntel>
            <div>
                <div className="flex justify-between items-center max-lg:items-start">
                    <div className="flex items-center gap-2 w-full flex-wrap">
                        {tabs?.map((tab, index) => {
                            return (
                                <button
                                    key={index}
                                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 px-2 py-1.5 grid place-items-center capitalize ${
                                        currentTab === tab
                                            ? 'bg-[#395BA9] text-[#fff] font-medium text-[13px]'
                                            : 'text-[#64748B] font-normal text-[13px]'
                                    }`}
                                    onClick={() => setCurrentTab(tab)}
                                >
                                    {tab}
                                </button>
                            );
                        })}
                    </div>
                    <input
                        className="border border-[#E2E8F0] bg-transparent p-1.5 placeholder:text-[#94A3B8] placeholder:text-sm placeholder:font-normal rounded outline:none"
                        placeholder="Search..."
                        value={search}
                        onChange={(event) => setSearch(event?.target?.value)}
                    />
                </div>
                {switch_on && compliance_intel?.length ? (
                    <div className=" mt-[1.5rem] mb-10 transition ease-in-out duration-500">
                        <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center">
                            <div className="flex items-start !h-[24px] gap-2 w-[85%]">
                                <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                                <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0 w-fit xl:block md:hidden">
                                    {compliance_intel?.length}
                                </p>
                                <Carousel
                                    slides={compliance_intel}
                                    handleOpen={handleOpen}
                                    type="Compliance"
                                    data={all_smart_intel?.Compliance}
                                    containerStyles="page-slide !h-[24px]"
                                />
                            </div>
                            <img src={ArrowRight} alt="ArrowRight" />
                        </div>
                    </div>
                ) : null}

                <div className="mt-4">
                    <RenderTab />
                </div>

                {/* <ViewThreatDrawer
                open={openDrawer}
                handleClose={() => setOpenDrawer(false)}
                currentData={currentData}
                type={type}
                currentTag={currentTag}
                 /> */}
            </div>
        </SmartIntel>
    );
};

export default Framwworks;
