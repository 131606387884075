import { validation } from 'validate';

export const AMLVendorsValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values.name, 'Vendor Name', true);
    const serviceDescription = validation(values.description, 'Description of Service', true);
    const dataShared = validation(values.data, 'Data shared', true);
    const riskLevel = validation(values.risk_level, 'Risk Level', true);
    const min_riskLevel = validation(values.min_risk_level, 'Minimnum Risk Level', true);
    const products = validation(values.products, 'Products', true);
    const email = validation(values.email, 'Email', true);

    if (!nameValidate.isValid) {
        errors.name = nameValidate.errorMessage;
    }
    if (!serviceDescription.isValid) {
        errors.description = serviceDescription.errorMessage;
    }
    if (!dataShared.isValid) {
        errors.data = dataShared.errorMessage;
    }
    if (!riskLevel.isValid) {
        errors.risk_level = riskLevel.errorMessage;
    }
    if (!min_riskLevel.isValid) {
        errors.min_risk_level = min_riskLevel.errorMessage;
    }
    if (!products.isValid) {
        errors.products = products.errorMessage;
    }
    if (!email.isValid) {
        errors.email = email.errorMessage;
    }
    return errors;
};

export const vendorsValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values.name, 'Vendor Name', true);
    const serviceDescription = validation(values.description, 'Description of Service', true);
    const dataShared = validation(values.data, 'Data shared', true);
    const riskLevel = validation(values.risk_level, 'Risk Level', true);
    const min_riskLevel = validation(values.min_risk_level, 'Minimnum Risk Level', true);
    const products = validation(values.products, 'Products', true);
    const email = validation(values.email, 'Email', true);

    if (!nameValidate.isValid) {
        errors.name = nameValidate.errorMessage;
    }
    if (!serviceDescription.isValid) {
        errors.description = serviceDescription.errorMessage;
    }
    if (!dataShared.isValid) {
        errors.data = dataShared.errorMessage;
    }
    if (!riskLevel.isValid) {
        errors.risk_level = riskLevel.errorMessage;
    }
    if (!min_riskLevel.isValid) {
        errors.min_risk_level = min_riskLevel.errorMessage;
    }
    if (!products.isValid) {
        errors.products = products.errorMessage;
    }
    if (!email.isValid) {
        errors.email = email.errorMessage;
    }
    return errors;
};

export const uploadVendorResponseValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values.full_name, 'Vendor Name', true);
    const serviceDescription = validation(values.service_description, 'Description of Service', true);
    const dataShared = validation(values.data_shared, 'Data shared', true);
    const vendor_type = validation(values.is_aml_vendor, 'Questionaire Type', true);
    const riskLevel = validation(values.risk_level, 'Risk Level', true);
    const min_riskLevel = validation(values.min_risk_level, 'Minimnum Risk Level', true);
    const products = validation(values.products, 'Products', true);
    const email = validation(values.email, 'Email', true);

    if (!nameValidate.isValid) {
        errors.full_name = nameValidate.errorMessage;
    }
    if (!serviceDescription.isValid) {
        errors.service_description = serviceDescription.errorMessage;
    }
    if (!dataShared.isValid) {
        errors.data_shared = dataShared.errorMessage;
    }
    if (!min_riskLevel.isValid) {
        errors.min_risk_level = min_riskLevel.errorMessage;
    }
    if (!riskLevel.isValid) {
        errors.risk_level = riskLevel.errorMessage;
    }
    if (!products.isValid) {
        errors.products = products.errorMessage;
    }
    if (!riskLevel.isValid) {
        errors.risk_level = riskLevel.errorMessage;
    }
    if (!min_riskLevel.isValid) {
        errors.min_risk_level = min_riskLevel.errorMessage;
    }
    if (!vendor_type.isValid) {
        errors.is_aml_vendor = vendor_type.errorMessage;
    }
    if (!email.isValid) {
        errors.email = email.errorMessage;
    }
    return errors;
};
export const uploadExistingVendorResponseValidation = (values) => {
    const errors = {};
    const vendorValidate = validation(values.vendor_id, 'Vendor name', true);

    if (!vendorValidate.isValid) {
        errors.vendor_id = vendorValidate.errorMessage;
    }
    return errors;
};
