import { Stack, Box, Typography, MenuItem, Select } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import WestIcon from '@mui/icons-material/West';

// import { useTheme } from '@mui/material/styles';
import ResponseItemContainer from './Components/ResponseItemContainer';
import ResponseBody from './Components/components/ResponseBody';
import VendorScoreCard from './Components/components/VendorScoreCard';
// import ResponseHeader from './Components/components/ResponseHeader';

const VendorResponseView = (props) => {
    const {
        questionnaire_forms,
        // all_responses,
        activeTab,
        setActiveTab,
        vendor,
        handleChangeView,
        goBack,
        questionnaireData,
        loading = { loading },
        handleChange,
        questionnaire,
        vendor_responses,
    } = props;
    const merchant = useSelector((state) => state?.authReducers?.company_details);
    // const theme = useTheme();
    const [activeQ, setActiveQ] = useState({ id: null });

    const currentResponse = useMemo(() => {
        return vendor_responses?.find((response) => response?.id === activeTab);
    }, [activeTab]);

    const currentQuestion = useMemo(() => {
        return questionnaire_forms?.find((question) => question?.title === currentResponse?.form_title);
    }, [currentResponse]);

    const [openScorecard, setOpenScorecard] = useState(false);

    const openScoreCard = () => {
        const selected = questionnaireData.find((q) => q.id == questionnaire);
        setActiveQ(selected || {});

        setOpenScorecard(true);
    };

    const handleClose = () => {
        setOpenScorecard(false);
    };

    const handleSelect = (e) => {
        const selected = questionnaireData.find((q) => q.id === e.target.value);
        setActiveQ(selected || {});
        handleChange(e);
    };
    const responses = useMemo(
        () =>
            questionnaireData?.map((q) => {
                if (q.has_responded) {
                    return (
                        <MenuItem key={q?.id} value={q?.id}>
                            {q?.name}
                        </MenuItem>
                    );
                }
            }),
        [questionnaireData]
    );

    return (
        <Box sx={{ height: '100dvh', width: '100%', overflow: 'hidden' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                    borderBottom: '1px solid #F1F5F9',
                    p: 2,
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    zIndex: 100,

                    height: 'fit-const first = useContext(second)',
                }}
            >
                <Box sx={{}}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <WestIcon color="#64748B" onClick={goBack} sx={{ cursor: 'pointer' }} />
                        <Typography sx={{ color: '#202D66', fontSize: '18px', fontWeight: 700, ml: 1.5 }}>
                            Vendor response ({currentResponse?.vendor_details?.name || merchant?.name})
                        </Typography>
                    </Box>
                    <Typography sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500, ml: 1.5, mt: 0.5 }}>
                        Responded by: {currentResponse?.vendor_details?.email}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: 'fit-content',
                        gap: 2,
                    }}
                >
                    <Typography
                        sx={{
                            backgroundColor: '#F1F5F9',
                            color: '#202D66',
                            fontSize: '13px',
                            fontWeight: 500,
                            textTransform: 'inherit',
                            px: 1.5,
                            py: 1,
                            borderRadius: 1,
                            cursor: 'pointer',
                            height: 'fit-content',
                        }}
                        onClick={openScoreCard}
                    >
                        View score card
                    </Typography>

                    <Select
                        sx={{
                            py: '0',
                            borderColor: '#e2e8f0',
                            height: 'fit-content',
                            color: '#395BA9',
                            borderRadius: '4px',
                            '&:hover': {
                                borderColor: '#CBD5E1',
                            },

                            '& .MuiSelect-select': {
                                display: 'flex',
                                alignItems: 'center',
                                padding: '6px 8px',
                                gap: '3px',
                            },
                        }}
                        onChange={handleSelect}
                        value={questionnaire}
                    >
                        {responses}
                    </Select>
                </Box>
            </Box>
            <Box
                sx={
                    {
                        /* height: '100dvh',
                    width: 'calc(100%)',
                    display: 'flex',
                    alignItems: 'center',

                    overflow: 'auto', */
                    }
                }
            >
                <Stack
                    direction="row"
                    sx={{
                        width: '100%',
                        overflow: 'auto',
                        height: '93dvh',
                    }}
                >
                    <Box
                        component="nav"
                        sx={{
                            width: { md: 290 },
                            flexShrink: { md: 0 },
                            position: 'sticky',
                            top: 0,
                            left: 0,
                        }}
                    >
                        <ResponseItemContainer
                            largeScreen={true}
                            all_questions={vendor_responses}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            handleChangeView={handleChangeView}
                        />
                    </Box>
                    <Stack
                        sx={{
                            height: 'fit-content',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            pt: 3,
                            pb: 10,
                        }}
                    >
                        {/* <ResponseHeader
                            id={currentResponse?.id}
                            key={currentResponse?.id}
                            activeTab={activeTab}
                            title={currentResponse?.form_title}
                            value={currentResponse?.score_percent}
                            openDrawer={openDrawer}
                            score_percent={currentResponse?.score_percent}
                            risk_level={currentResponse?.risk_level}
                        /> */}
                        <ResponseBody
                            key={currentQuestion?.id}
                            activeTab={activeTab}
                            title={currentResponse?.form_title}
                            questionID={currentQuestion?.id}
                            question={currentQuestion}
                            answers={currentResponse?.answer}
                            score_percent={currentResponse?.score_percent}
                            risk_level={currentResponse?.risk_level}
                        />
                    </Stack>
                </Stack>
                <VendorScoreCard
                    open={openScorecard}
                    handleClose={handleClose}
                    vendor={vendor}
                    active={activeQ}
                    vendor_responses={vendor_responses}
                    questionnaire_forms={questionnaire_forms}
                />
            </Box>
        </Box>
    );
};

export default VendorResponseView;
