import React from 'react';
import { Formik } from 'formik';

const IntegrationsForm = (props) => {
    const { initialValues, onSubmit, validate, children, fileURL } = props;

    const handleSubmit = (values, formikBag) => {
        // We'll pass all values since validation will handle filtering
        onSubmit(values, formikBag);
    };

    // Create a wrapper for the validate function that skips file inputs
    const validateWrapper = (values) => {
        // Filter out the fields that have already been uploaded via handleFileUpload
        const fieldsToValidate = Object.keys(values).reduce((acc, key) => {
            if (!fileURL?.name || key !== fileURL?.name) {
                acc[key] = values[key];
            }
            return acc;
        }, {});

        return validate(fieldsToValidate);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validateWrapper}
            enableReinitialize={true}
        >
            {() => <>{children}</>}
        </Formik>
    );
};

export default IntegrationsForm;
