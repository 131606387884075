import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { AppFormInput } from 'components/new_components/forms';
import AppFileUploadInput from 'components/new_components/AppFileUploadInput';
import { AppSubmitButton } from 'components/new_components/forms';

import { connect } from 'react-redux';
import { GetOneIntegration, CreateMerchantIntegration, UploadFile } from 'store/actions/integrationsActions';

// utils
import { integrationValidate } from './utils';
import { formatSentence, capitalizeFirstLetter } from 'utils';
import listImageIcon from 'assets/img/listicon.png';
import Bulb from 'assets/img/Bulb.svg';

//translations
import { useTranslation } from 'react-i18next';
import AppInput from 'components/new_components/AppInput';
import { validation } from 'validate';
import IntegrationsForm from './components/ImtegrationsForm';

const IntegrationConnect = (props) => {
    const theme = useTheme();

    //props
    const { one_integration, GetOneIntegration, CreateMerchantIntegration, UploadFile, selectedId, goBack } = props;
    // state
    const [loading, setLoading] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const [fileURL, setfileURL] = useState({});
    const [integration_name, setIntegrationName] = useState('');

    //translation
    const { t } = useTranslation('setups');
    const language = localStorage.getItem('i18nextLng');

    // functions
    const getOneIntegration = async () => {
        setLoading({ ...loading, content: false });
        const res = await GetOneIntegration(selectedId);
        setLoading({ ...loading, content: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const handleSubmit = async (values) => {
        // Check if all required files have been uploaded
        const requiredFileFields = one_integration?.fields?.filter((field) => field.type?.toLowerCase() === 'file');

        const missingFiles = requiredFileFields?.some((field) => !fileURL?.name || fileURL.name !== field.name);

        if (missingFiles) {
            toast.error('Please upload all required files');
            return;
        }

        setLoading({ ...loading, sumbit: true });

        const submissionData = {
            fields: fileURL.file_url ? { ...values, [fileURL.name]: fileURL.file_url } : values,
            integration: selectedId,
            connection_type: 'manual',
            user_scopes: one_integration?.integration_scopes,
            name: integration_name,
        };

        const res = await CreateMerchantIntegration(submissionData);
        setLoading({ ...loading, sumbit: false });

        if (res?.success) {
            toast.success(`You have successfully integrated ${one_integration?.name} to your dashboard.`);
            goBack();
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.error(res?.message);
            }
        }
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files?.[0];
        const fieldName = e.target.name;

        // Validate the file here if needed
        const valid = validation(file, fieldName?.replace(/_/g, ' '), true);
        if (!valid.isValid) {
            toast.error(valid.errorMessage);
            return;
        }

        const formData = new FormData();
        formData.append('file_upload', file);
        formData.append('integration_name', one_integration?.name);

        const res = await UploadFile(formData);
        if (res?.success) {
            setfileURL({ name: fieldName, file_url: res?.file_url });
        } else {
            toast.error(res?.message);
        }
    };

    // useeffect
    useEffect(() => {
        getOneIntegration();
    }, []);
    useEffect(() => {
        const fields = {};
        one_integration?.fields?.forEach((field) => {
            fields[field.name] = '';
        });
        setInitialValues(fields);
    }, [one_integration]);

    const integratin_name = one_integration?.name && capitalizeFirstLetter(one_integration?.name);
    return (
        <Stack spacing={4} direction="row" sx={{ p: 4, minHeight: '100%' }}>
            <Box sx={{ minWidth: '50%', maxWidth: 550 }}>
                <Typography
                    variant="h3"
                    component="h3"
                    sx={{
                        color: theme.palette.primary[700],
                        fontWeight: 600,
                        fontSize: 18,
                    }}
                >
                    {t('integrationsConnect.oneStep')}
                </Typography>
                <Typography sx={{ fontWeight: 400, color: theme.palette.gray[700] }}>
                    {t('integrationsConnect.authenticate')}
                </Typography>
                <Box sx={{ maxWidth: 500, my: 2 }}>
                    <IntegrationsForm
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validate={integrationValidate}
                        fileURL={fileURL}
                    >
                        <AppInput
                            type="text"
                            label="Integration Name"
                            name="integration_name"
                            placeholder="Enter Preferred Integration Name"
                            value={integration_name}
                            onChange={(event) => setIntegrationName(event.target.value)}
                        />
                        {Object.keys(initialValues).map((field, indx) => {
                            return one_integration?.fields?.[indx]?.type?.toLowerCase() === 'text' ? (
                                <AppFormInput
                                    type="text"
                                    label={formatSentence(field)?.replace('_', ' ')}
                                    name={field}
                                    placeholder={formatSentence(field)?.replace('_', ' ')}
                                    key={field}
                                />
                            ) : (
                                <AppFileUploadInput
                                    type="file"
                                    label={formatSentence(field)?.replace('_', ' ')}
                                    onChange={handleFileUpload}
                                    name={field}
                                    key={field}
                                />
                            );
                        })}
                        <Box sx={{ mt: 2 }}>
                            <AppSubmitButton
                                text={t('integrationsConnect.proceed')}
                                variant="contained"
                                color="primary"
                                loading={loading.sumbit}
                            />
                        </Box>
                    </IntegrationsForm>
                </Box>
            </Box>
            {loading.content ? (
                <Stack
                    sx={{
                        backgroundColor: theme.palette.gray[50],
                        p: 2,
                        py: 3,
                        borderRadius: 4,
                        minWidth: 500,
                        maxWidth: 550,
                        width: '50%',
                    }}
                >
                    <Stack alignItems="center" component="header" sx={{ textAlign: 'center' }}>
                        <Box component="img" src={one_integration?.logo} alt="" sx={{ width: 'auto', height: 65 }} />
                        <Typography
                            sx={{
                                fontWeight: 700,
                                color: theme.palette.primary[700],
                                fontSize: 20,
                                mt: 2,
                                mb: 1.5,
                            }}
                        >
                            {t('integrationsConnect.howTo')} {capitalizeFirstLetter(one_integration?.name)}.
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                color: theme.palette.gray[500],
                                fontSize: 15,
                                maxWidth: 400,
                                width: '90%',
                            }}
                        >
                            {t('integrationsConnect.integratingWith1')} {capitalizeFirstLetter(one_integration?.name)}{' '}
                            {t('integrationsConnect.integratingWith2')}
                        </Typography>
                    </Stack>
                    <Box
                        dangerouslySetInnerHTML={{
                            __html: language?.includes('fr')
                                ? one_integration?.tips_fr?.replaceAll('_', ' ')
                                : language?.includes('en')
                                ? one_integration?.tips_en?.replaceAll('_', ' ')
                                : one_integration?.tips?.replaceAll('_', ' '),
                        }}
                        sx={{
                            '& ul, & ol': {
                                my: 2,
                                '& li': {
                                    listStyle: `url(${listImageIcon})`,
                                    ml: 4,
                                    p: 1,
                                },
                            },
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #395BA9',
                            borderRadius: 2,
                            width: '80%',
                            mt: 2,
                            ml: 4.5,
                            py: 0.5,
                            px: 1,
                        }}
                    >
                        <img src={Bulb} alt="" width={'15px'} height={'15px'} />
                        <Typography sx={{ fontSize: '11px', ml: 1.5 }}>
                            Integration to {`{${integratin_name}} `} is a <strong>READ-ONLY ACCESS</strong>
                        </Typography>
                    </Box>
                </Stack>
            ) : (
                t('integrationsConnect.loading')
            )}
        </Stack>
    );
};

const mapStateToProps = (state) => {
    return {
        one_integration: state?.integrationsReducers?.one_integration,
    };
};
export default connect(mapStateToProps, {
    GetOneIntegration,
    CreateMerchantIntegration,
    UploadFile,
})(IntegrationConnect);
