import React, { useEffect, useMemo } from 'react';
// import { connect } from 'react-redux';
import { toast } from 'react-toastify';
// import { GetVendorResponses } from 'store/actions/vendorActions';
import SquareIcon from '@mui/icons-material/Square';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import AppTag from 'components/new_components/AppTags';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import VendorDonutChart from 'views/merchant/VendorManagement/Vendors/MyVendors/components/VendorDonutChart';

const VendorScorecard = (props) => {
    const theme = useTheme();
    const { GetVendorResponses, vendor_responses, details, open, active } = props;

    const getVendorRepsonses = async () => {
        const res = await GetVendorResponses(details?.id);
        if (!res.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (details?.id) {
            getVendorRepsonses();
        }
    }, [details?.id, open]);

    const bgColor = '#E2E8F0';

    const values = useMemo(
        () => [
            [
                active?.overall_score_percent,
                active?.overall_score_percent < 34
                    ? '#FF5449'
                    : active?.overall_score_percent > 33 && active?.overall_score_percent < 67
                    ? '#F2C021'
                    : '#55BE8B',
            ],
        ],
        [active]
    );

    // console.log(
    //     all_responses
    //         ?.sort((a, b) => a?.form_id - b?.form_id)
    //         ?.filter((response) => response?.vendor_details?.id === JSON?.parse(vendor))
    // );

    return (
        <>
            {vendor_responses?.length > 0 ? (
                <Box sx={{ mt: 3, width: 'fit-content' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mr: 3,
                            position: 'relative',
                            width: '35rem',
                        }}
                    >
                        <VendorDonutChart
                            values={values}
                            bgColor={bgColor}
                            lineWidth={8}
                            height={240}
                            width={240}
                            centerContent={
                                <Typography
                                    sx={{
                                        position: 'relative',
                                        color: '#202D66',
                                        fontWeight: 700,
                                        fontSize: '34px',
                                    }}
                                >
                                    {active?.overall_score_percent}
                                </Typography>
                            }
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                        <Box
                            sx={{
                                borderRight: '1px solid',
                                borderColor: theme.palette.grey[200],
                                px: 3,
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <SquareIcon sx={{ color: '#FF5449', borderRadius: 3 }} />
                                <Typography
                                    sx={{
                                        color: theme.palette.neutral[500],
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        ml: 0.5,
                                    }}
                                >
                                    0 - 33
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    color: theme.palette.primary[40],
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    textAlign: 'center',
                                }}
                            >
                                High
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                borderRight: '1px solid',
                                borderColor: theme.palette.grey[200],
                                px: 3,
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <SquareIcon sx={{ color: '#F2C021', borderRadius: 3 }} />
                                <Typography
                                    sx={{
                                        color: theme.palette.neutral[500],
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        ml: 0.5,
                                    }}
                                >
                                    34 - 66
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    color: theme.palette.primary[40],
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    textAlign: 'center',
                                }}
                            >
                                Medium
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                px: 3,
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <SquareIcon sx={{ color: theme.palette.success[70], borderRadius: 3 }} />
                                <Typography
                                    sx={{
                                        color: theme.palette.neutral[500],
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        ml: 0.5,
                                    }}
                                >
                                    67 - 100
                                </Typography>
                            </Box>

                            <Typography
                                sx={{
                                    color: theme.palette.primary[40],
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    textAlign: 'center',
                                }}
                            >
                                Low
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        {vendor_responses?.map((response) => (
                            <Box
                                key={response?.id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderTop: '2px solid #F1F5F9',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#64748B',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        width: '60%',
                                        py: 1,
                                    }}
                                >
                                    {response?.form_title}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'center',
                                        width: '40%',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.primary[40],
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            borderLeft: '2px solid #F1F5F9',
                                            py: 1,
                                            pl: 1,
                                            width: '4rem',
                                        }}
                                    >
                                        {response?.score_percent}%
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginLeft: '3rem',
                                            py: 1,
                                        }}
                                    >
                                        <AppTag
                                            text={response.risk_level}
                                            icon={
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: 12,
                                                        height: 12,
                                                        color:
                                                            response.risk_level === 'low'
                                                                ? theme.palette.success[500]
                                                                : response.risk_level === 'medium'
                                                                ? theme.palette.warning[900]
                                                                : response.risk_level === 'high'
                                                                ? theme.palette.error[500]
                                                                : theme.palette.error[300],
                                                        mr: 0.5,
                                                    }}
                                                />
                                            }
                                            noRounded
                                            noItalic
                                            type="default"
                                            sx={{
                                                padding: '4px',
                                                borderColor: theme.palette.grey[200],
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
                    <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#5A38F4' }}>
                        Vendor has not responded
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default VendorScorecard;
