import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Drawer } from '@mui/material';

// core components
import SidebarContent from './SidebarContent';
import { useSelector } from 'react-redux';

const SidebarDrawer = (props) => {
    const theme = useTheme();
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const banner_open = useSelector((state) => state?.generalReducers?.banner_open);
    const { largeScreen, open, onClose, routes, merchant_info, title, ref2 } = props;

    return (
        <Drawer
            variant={largeScreen ? 'permanent' : 'temporary'}
            open={open}
            onClose={onClose}
            ModalProps={{ keepMounted: true }}
            sx={{
                display: largeScreen ? { xs: 'none', md: 'block' } : { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '275px',
                    border: 'none',
                    height:
                        banner_open && user_info?.renewal_request_info?.show_banner ? 'calc(100dvh - 3rem)' : '100dvh',
                    mt: banner_open && user_info?.renewal_request_info?.show_banner ? '3rem' : 0,
                    backgroundColor: theme.palette.primary[900],
                },
            }}
        >
            <SidebarContent routes={routes} merchant_info={merchant_info} title={title} ref2={ref2} />
        </Drawer>
    );
};
export default SidebarDrawer;
