import React from 'react';

const VendorDonutChart = (props) => {
    const {
        values = [],
        width = 100,
        height = 100,
        bgColor,
        lineWidth = 5,
        unusedLineWidth = lineWidth * 0.8, // 80% thickness of the used part
        centerContent,
    } = props;

    const r = 15.91549430918954; // Radius of the donut
    const circumference = 2 * Math.PI * r;
    const sum = values.reduce((a, b) => a + b[0], 0);

    // Add the unused part to complete 100%
    if (sum < 100 && bgColor) {
        values.push([100 - sum, bgColor]);
    }

    const aggregated = values.reduce(
        (output, item) => {
            const [value, color] = item;
            const length = (circumference / 100) * value;
            const fillLength = circumference - length;
            const dashArray = [length, fillLength];
            const rotation = 25 - output.total;
            output.total += value;
            output.items.push({
                dashArray: dashArray.join(' '),
                rotation,
                color,
                strokeWidth: output.total === 100 ? unusedLineWidth : lineWidth, // Apply unused thickness only to the last item
                isUnused: output.total === 100,
            });
            return output;
        },
        {
            total: 0,
            items: [],
        }
    );

    return (
        <div
            style={{
                position: 'relative',
                width,
                height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <svg width={width} height={height} viewBox="0 0 40 40">
                {/* Render all "used" parts first */}
                {aggregated.items
                    .filter((item) => !item.isUnused)
                    .map(({ dashArray, rotation, color, strokeWidth }, i) => (
                        <circle
                            key={`used_circle_${i}`}
                            cx="20"
                            cy="20"
                            r={r}
                            style={{
                                boxShadow: '0 10px 16px rgba(0, 0, 0, 0.2)',
                            }}
                            fill="transparent"
                            stroke={color}
                            strokeWidth={strokeWidth}
                            strokeDasharray={dashArray}
                            strokeDashoffset={rotation}
                        />
                    ))}
                {/* Render the "unused" part last to overlap */}
                {aggregated.items
                    .filter((item) => item.isUnused)
                    .map(({ dashArray, rotation, color, strokeWidth }, i) => (
                        <circle
                            key={`unused_circle_${i}`}
                            cx="20"
                            cy="20"
                            style={{
                                boxShadow: '0 10px 16px rgba(0, 0, 0, 0.2)',
                            }}
                            r={r}
                            fill="transparent"
                            stroke={color}
                            strokeWidth={strokeWidth}
                            strokeDasharray={dashArray}
                            strokeDashoffset={rotation}
                        />
                    ))}
            </svg>
            <div
                style={{
                    position: 'absolute',
                    width, // Inner circle size
                    height,
                    top: 0,
                    left: 0,
                    // background: 'white',
                    fontSize: '2.5rem',
                    borderRadius: '50%',
                    // boxShadow: '0 10px 16px rgba(0, 0, 0, 0.2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {centerContent}
            </div>
        </div>
    );
};

export default VendorDonutChart;
