import { Tooltip, useTheme } from '@mui/material';

export default function VendorTooltip({ sx, children, ...rest }) {
    const theme = useTheme();
    return (
        <Tooltip
            arrow
            PopperProps={{
                sx: {
                    '& .MuiTooltip-arrow': {
                        color: '#000',
                    },
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: '#000',
                        color: theme.palette.white.main,
                        p: 1,
                    },
                    ...sx,
                },
            }}
            {...rest}
        >
            {children}
        </Tooltip>
    );
}
