import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, CircularProgress, Divider, IconButton, Menu, MenuItem } from '@mui/material';
// import { MoreVert } from '@mui/icons-material';
import axiosInstance from '../../../../../utils/https';

//translations
import { useTranslation } from 'react-i18next';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { generateVendorRiskReport } from '../../utils/generateVendorRiskReport';
import { toast } from 'react-toastify';
import QuestionnaireCountTooltip from './components/QuestionnaireCountTooltip';

const VendorsTableActions = (props) => {
    const theme = useTheme();
    const { row, openModal, openResendModal, openDeleteModal, openDetailsModal } = props;

    // state
    const [anchor, setAnchor] = useState(null);
    const [reportLoading, setReportLoading] = useState(false);
    const history = useHistory();

    //translation
    const { t } = useTranslation('vendorManagement');
    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const handleDownloadReport = async () => {
        try {
            setReportLoading(true);
            console.log(row?.questionnaire_history, 'q history');
            const questionnaireId = row?.questionnaire_history?.[0]?.id || null;
            const queryParams = new URLSearchParams({
                vendor_id: row?.id,
                vendor_questionnaire: questionnaireId,
            });
            // Remove any undefined or null values from the query params
            queryParams.forEach((value, key) => {
                if (value === null || value === undefined || value === '') {
                    queryParams.delete(key);
                }
            });
            const res = await axiosInstance().get(`/compliance/vendor_report/?${queryParams.toString()}`);

            generateVendorRiskReport(res.data, row?.is_aml_vendor);
            if (res.status !== 'success') {
                throw new Error(res.message || 'Failed to fetch vendor report');
            }
        } catch (error) {
            toast.error(error.message || 'An error occurred while fetching data');
        } finally {
            setReportLoading(false);
        }
    };

    const openEditModal = () => {
        closeMenu();
        const payload = {
            name: row?.name,
            description: row?.description,
            data: row?.data,
            risk_level: row?.risk_level?.toLowerCase(),
            products: row?.products,
            email: row?.email,
            min_risk_level: row?.min_risk_level,
            ran_aml: row?.ran_aml,
            is_aml_vendor: row?.is_aml_vendor,
        };
        openModal('edit', payload, row?.id);
    };

    const handleOpenDeleteModal = () => {
        closeMenu();
        openDeleteModal(row?.id);
    };
    return (
        <div className="flex gap-5 items-center justify-end ">
            <QuestionnaireCountTooltip row={row} theme={theme} />
            <Button
                variant="outlined"
                onClick={() => {
                    openDetailsModal(row);
                    closeMenu();
                }}
                sx={{
                    borderRadius: '8px',
                    py: '4px',
                    textTransform: 'capitalize',
                    borderColor: theme.palette.gray[200],
                    height: 'fit-content',
                    color: '#475569',
                }}
            >
                {t('table.tableAction.view_detail')}
            </Button>
            <IconButton sx={{ color: theme.palette.gray[900], ml: -1 }} onClick={openMenu}>
                <MoreHoriz
                    sx={{
                        bgcolor: '#F1F5F9',
                        px: '2px',
                        height: '2rem',
                        borderRadius: '4px',
                    }}
                />
            </IconButton>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}
                PaperProps={{
                    style: {
                        width: 180,
                    },
                }}
            >
                {row?.questionnaire_history?.[0]?.has_responded && (
                    <MenuItem
                        onClick={() => {
                            history.push(
                                `/vendor/responses/${row?.id}?questionnaire=${row?.questionnaire_history?.[0]?.id}`
                            );
                            closeMenu();
                        }}
                        sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                    >
                        {t('table.tableAction.view_response')}
                    </MenuItem>
                )}
                {row?.questionnaire_history?.[0]?.has_responded && <Divider />}
                <MenuItem
                    onClick={openEditModal}
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                    disabled={user_type === 'auditor' || role === 'auditor'}
                >
                    {t('table.tableAction.edit')}
                </MenuItem>
                <Divider />
                {/*   <MenuItem
                    onClick={openEditModal}
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                    disabled={user_type === 'auditor' || role === 'auditor'}
                >
                    {t('table.tableAction.sla')}
                </MenuItem> */}
                {row?.questionnaire_history?.[0]?.id && (
                    <>
                        <MenuItem
                            onClick={handleDownloadReport}
                            sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                            disabled={user_type === 'auditor' || role === 'auditor'}
                        >
                            {t('table.tableAction.report')}
                            {reportLoading && <CircularProgress size={20} sx={{ color: '#64748B', ml: 1 }} />}
                        </MenuItem>
                        <Divider />
                    </>
                )}

                <MenuItem
                    onClick={() => {
                        openResendModal(row?.id);
                        closeMenu();
                    }}
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                    disabled={user_type === 'auditor' || role === 'auditor'}
                >
                    {t('table.tableAction.resend_questionaire')}
                </MenuItem>

                <Divider />
                <MenuItem
                    onClick={handleOpenDeleteModal}
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#FF5449', py: 0.2 }}
                    disabled={user_type === 'auditor' || role === 'auditor'}
                >
                    {t('table.tableAction.delete')}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default VendorsTableActions;
